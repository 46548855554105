import {apiUrl} from "@/core/api/index.js";

export class Categories {
    static async getAllCategories() {
        const response = await fetch(`${apiUrl}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                query: `{
                  categories {
                    edges {
                      node {
                        uuid
                        name
                        image
                      }
                    }
                  }
                }`
            })
        });
        return await response.json();
    }

    static async getCategoryById(id) {
        const response = await fetch(`${apiUrl}/storefront/products/${id}`);
        return await response.json();
    }
}
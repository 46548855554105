<template>
  <div class="filter">
    <div class="filter__top">
      <p class="filter__title">FILTER</p>
      <button class="filter__clear" @click="clearAllFilters">
        <img src="@icons/crossFilledIcon.svg" alt="clear">
        Clear
      </button>
    </div>
    <el-collapse class="collapse" v-model="activeNames">
      <el-collapse-item class="price" title="PRICE" name="1">
        <el-slider class="price__slider" v-model="price" range :max="100" />
        <div class="price__inputs">
          <div class="price__block">
            <input class="price__block-input" type="text" v-model="price[0]" @input="checkPriceRange" />
            <p>€</p>
          </div>
          <div class="price__block">
            <input class="price__block-input" type="text" v-model="price[1]" @input="checkPriceRange" />
            <p>€</p>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item class="checkboxes" title="CATEGORIES" name="2">
        <div class="checkboxes__wrapper">
          <div class="checkboxes__item" v-for="(category, idx) in categories" :key="idx">
            <input type="checkbox" v-model="categoryCheckboxes" class="size__checkbox" :id="'sizeCheckbox' + idx" :value="category.node.name">
            <label class="checkboxes__label" :for="'sizeCheckbox' + idx">{{ category.node.name }}</label>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item class="checkboxes" title="SIZE" name="3">
        <div class="checkboxes__wrapper">
          <div class="checkboxes__item" v-for="(size, idx) in sizes" :key="idx">
            <input type="checkbox" v-model="sizesCheckboxes" class="size__checkbox" :id="'sizeCheckbox' + idx" :value="size">
            <label class="checkboxes__label" :for="'sizeCheckbox' + idx">{{ size }}</label>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item class="checkboxes" title="COLOR" name="4">
        <div class="checkboxes__wrapper">
          <div class="checkboxes__item" v-for="(color, idx) in colors" :key="idx">
            <input type="checkbox" v-model="colorsCheckboxes" class="checkboxes__checkbox" :id="'colorCheckbox' + idx" :value="color">
            <label class="checkboxes__label-color" :style="{ 'background-color': color }" :for="'colorCheckbox' + idx"></label>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item class="checkboxes" title="TAGS" name="5">
        <div class="checkboxes__wrapper">
          <div class="checkboxes__item" v-for="(tag, idx) in tags" :key="idx">
            <input type="checkbox" v-model="tagsCheckboxes" class="checkboxes__checkbox" :id="'tagCheckbox' + idx" :value="tag">
            <label class="checkboxes__label" :for="'sizeCheckbox' + idx">{{ tag.replace(/_/g, ' ') }}</label>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {debounce} from "lodash";
import {Categories} from "@/core/api/categories.js";
import {useRoute} from "vue-router";

const route = useRoute()

const emit = defineEmits(['clearFilters', 'filterPrice', 'filterCategory', 'filterSize', 'filterColor', 'filterTag'])

const activeNames = ref(['1'])
const categories = ref([])
const categoryCheckboxes = ref([])
const sizesCheckboxes = ref([])
const colorsCheckboxes = ref([])
const tagsCheckboxes = ref([])

const price = ref([0, 100])
const checkPriceRange = () => {
  if(price[0] < 0) price[0] = 0;
  if(price[0] > 100) price[0] = 100;
  if(price[1] < 0) price[1] = 0;
  if(price[1] > 100) price[1] = 100;
  if(price[0] > price[1]) [price[0], price[1]] = [price[1], price[0]];
}

const sizes = [
  "S", "M", "L", "XL"
]
const colors = [
  "#000000", "#ffffff"
]
const tags = [
  "trend", "special_offers", "latest", "featured"
]

const getCategories = async () => {
  const res = await Categories.getAllCategories()
  categories.value = res.data.categories.edges
}

const clearAllFilters = () => {
  emit('clearFilters', price.value = [0, 100], categoryCheckboxes.value = [], sizesCheckboxes.value = [], colorsCheckboxes.value = [], tagsCheckboxes.value = [])
}

watch(price, debounce((newPrice) => {
    emit('filterPrice', newPrice);
}, 500), {deep: true});
watch(categoryCheckboxes, debounce((newCategories) => {
    emit('filterCategory', newCategories);
}, 500), {deep: true});
watch(sizesCheckboxes, debounce((newSizes) => {
    emit('filterSize', newSizes);
}, 500), {deep: true});
watch(colorsCheckboxes, debounce((newColors) => {
    emit('filterColor', newColors);
}, 500), {deep: true});
watch(tagsCheckboxes, debounce((newTags) => {
    emit('filterTag', newTags);
}, 500), {deep: true});
watch(() => route.query['categories'], (newVal) => {
  if (newVal) {
    categoryCheckboxes.value = [newVal];
  }
});

onMounted(async () => {
  await getCategories()

  if (route.query['categories']) {
    categoryCheckboxes.value = [route.query['categories']]
  }

  if (route.query['tags']) {
    tagsCheckboxes.value = [route.query['tags']]
  }
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.filter {
  width: 250px;
  padding-inline: 10px;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: rgba(35,56,81,1);
    font-weight: 700;
    font-size: 15px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__clear {
    cursor: pointer;
    background-color: $blue;
    padding: 2px 7px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    gap: 3px;
    transition: 0.2s;

    color: $white;
    font-size: 12px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }
  }
}

.collapse {
  margin-top: 15px;
}

.price {

  &__slider {
    width: 90%;
    margin-inline: auto;
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 5px;

    &-input {
      width: 85px;
      border: 1px solid #eeeeee;
      border-radius: 2px;
      padding-block: 2px;

      text-align: center;
      font-size: 16px;
      color: rgba(35,56,81,1);

      &:hover {
        border-color: #dcdcdc;
      }
    }

    & p {
      font-size: 16px;
      color: rgba(35,56,81,1);
    }
  }
}

.categories {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;

    & img {
      width: 36px;
      height: 36px;
      border: 0.5px solid rgba(221, 221, 221, 1);;
    }

    &-text {
      font-size: 13px;
      color: rgba(35,56,81,1);
    }

    &:hover img {
      border-color: rgba(143, 210, 238, 1);
      box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.15);
    }
  }
}

.checkboxes {

  &__wrapper {
    max-height: 250px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__checkbox {
    cursor: pointer;
  }

  &__label {
    text-transform: capitalize;
    cursor: pointer;
    font-size: 13px;
    color: rgba(35,56,81,1);

    &-color {
      width: 15px;
      height: 20px;
      border: 2px solid rgba(221, 221, 221, 1);
    }
  }
}
</style>
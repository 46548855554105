import { computed } from 'vue'
import { useRouter } from 'vue-router'
import {useAuthStore} from "@/stores/auth";

export const checkAuth = () => {
  const authStore = useAuthStore()
  const router = useRouter()

  const user = computed(() => authStore.user)

  setTimeout(() => {
    if (!user.value) {
      router
        .push({
          name: 'home'
        })
        .then(() => {
          console.log('User not authenticate')
        })
    }
  }, 1000)
}

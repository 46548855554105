<template>
  <div class="page">
    <h1 class="page__title">Shopping Cart</h1>
    <div class="container">
      <div class="page__wrapper" v-if="products.length">
        <div class="page__inner">
          <table class="page__table">
          <thead>
            <tr>
              <th>IMAGE</th>
              <th>PRODUCT NAME</th>
              <th>QUANTITY</th>
              <th>UNIT PRICE</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <product-in-cart
              v-for="product in products"
              :key="product.node.product.uuid"
              :product="product"
            />
          </tbody>
        </table>
        </div>
        <div class="page__checkout">
          <p class="page__checkout-title">What would you like to do next?</p>
          <p class="page__checkout-text">Sub-Total: <span>{{ totalPrice }}€</span></p>
          <p class="page__checkout-text">Total: <span>{{ totalPrice }}€</span></p>
          <router-link :to="({ name: 'checkout' })" class="page__checkout-button">
            CHECKOUT
            <img src="@icons/arrowIcon.svg" alt="continue">
          </router-link>
        </div>
      </div>
      <div v-else class="page__noProducts">
        <p class="page__noProducts-text">Your shopping cart is empty :(</p>
        <div class="page__line"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, reactive} from "vue";
import {useCartStore} from "@/stores/cart.js";
import ProductInCart from "@/components/product/product-in-cart.vue";

const cartStore = useCartStore()

const totalPrice = computed(() => {
  return cartStore.currentOrder ? cartStore.currentOrder.totalPrice : 0
})

const products = computed(() => {
  return cartStore.currentOrder.orderProducts ? cartStore.currentOrder.orderProducts.edges.map((product) => {
    return reactive({
      node: product.node,
      stepper: product.node.quantity,
    });
  }) : [];
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    padding-block: 50px;
    width: 85%;
    margin-inline: auto;
    display: flex;
    align-items: center;
    gap: 50px;
  }

  &__inner {
    width: 100%;
    overflow: auto;
  }

  &__table {
    width: 100%;
    border: 1px solid rgba(221,221,221,1);
    border-collapse: collapse;

    & tr {
      border-bottom: 1px solid rgba(221,221,221,1);
    }

    & th {
      padding: 8px 14px;
    }

    &-text {
      text-align: center;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;

      & div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    &-color {
      width: 15px;
      height: 20px;
      border: 2px solid rgba(221, 221, 221, 1);
    }

    &-buttons {
      height: 97px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    &-button {
      cursor: pointer;
      width: 36px;
      aspect-ratio: 1;
      display: grid;
      place-items: center;
      background-color: $blue;
      border-radius: 2px;
      transition: 0.2s;
      color: $white;

      & img {
        width: 25px;
        aspect-ratio: 1;
      }

      &:hover {
        background-color: $orange;
      }

      &:last-child {
        background-color: $red;

        &:hover {
          background-color: $light-blue;
        }
      }
    }
  }

  &__line {
    margin-top: 25px;
    width: 100%;
    height: 1px;
    background-color: rgba(221, 221, 221, 1);
  }

  &__noProducts {
    padding-block: 20px 100px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    &-text {
      font-size: 16px;
      color: rgba(35,56,81,1);
    }
  }

  &__checkout {
    min-width: 375px;
    background-color: rgba(238,238,238,1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    &-title {
      text-transform: uppercase;
      color: $dark-blue;
      font-weight: 700;
      font-size: 16px;

      &::after {
        content: '';
        width: 50px;
        height: 3px;
        background-color: $orange;
        display: block;
        position: relative;
        margin-top: 7px;
      }
    }

    &-text {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(221, 221, 221, 1);

      font-size: 16px;
      color: rgba(35,56,81,1);
      font-weight: 700;

      & span {
        font-size: 16px;
        font-weight: 500;
        color: rgba(35,56,81,1);
      }
    }

    &-button {
      cursor: pointer;
      background-color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding-block: 8px;
      border-radius: 2px;
      transition: 0.2s;

      font-size: 13px;
      font-weight: 700;
      color: $white;

      &:hover {
        background-color: $orange;
      }

      & img {
        width: 18px;
        aspect-ratio: 1;
      }
    }
  }
}

img {
  width: 90px;
  aspect-ratio: 1;
}

@media (max-width: 1000px) {
  .page {
    &__wrapper {
      display: block;
    }
    
    &__inner {
      margin-bottom: 40px;
    }

    &__checkout {
      min-width: 100%;
      max-width: 100%;
    }
  }
}
</style>
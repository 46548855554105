<template>
  <header class="header" id="top">
    <div class="header__top">
      <div class="container">
        <div class="header__top-wrapper">
          <router-link to="/" class="header__logo">
            <img src="@icons/logo.svg" alt="logo">
          </router-link>
          <div class="header__contact">
            <router-link to="/contact" class="header__contact-inner">
              <img src="@icons/mailIcon.svg" alt="icon">
              <div class="header__contact-texts">
                <h6>Contact</h6>
                <p>Leave us a message</p>
              </div>
            </router-link>
<!--            <router-link to="/blog" class="header__contact-inner">-->
<!--              <img src="@icons/blogsIcon.svg" alt="icon">-->
<!--              <div class="header__contact-texts">-->
<!--                <h6>Blog</h6>-->
<!--                <p>Latest news</p>-->
<!--              </div>-->
<!--            </router-link>-->
          </div>
        </div>
      </div>
    </div>
    <div class="header__bottom">
      <div class="container">
        <div class="header__bottom-wrapper">
          <div class="header__left">
            <div class="header__categories" @click="categoryDropdown = true">
              <img src="@icons/menuIcon.svg" alt="menu">
              Shop by Category
            </div>
            <div class="header__dropdown-category" ref="categoryDropdownRef" v-if="categoryDropdown">
              <router-link
                v-for="category in categories"
                :key="category.node.uuid"
                @click="categoryDropdown = false"
                :to="({ name: 'store', query: { categories: category.node.name } })"
                class="header__dropdown-item-category"
              >
                <img :src="category.node.image" alt="categories">
                {{ category.node.name }}
              </router-link>
            </div>
          </div>
          <div class="header__bottom-search">
            <div class="header__search-button">
              {{ selectedCategoryForSearch === '' ? 'All' : selectedCategoryForSearch}}
              <img class="header__search-arrow" src="@icons/arrowForSearch.svg" alt="arrow">
            </div>
            <div class="header__search-categories" v-if="categoriesForSearch.length > 1">
              <p
                  v-for="category in categoriesForSearch"
                  :key="category"
                  @click="selectCategoryForSearch(category)"
              >
                {{ category ? category : 'All' }}
              </p>
            </div>
            <input
              class="header__bottom-input"
              placeholder="Search here..."
              v-model="search"
              type="text"
            >
            <img src="@icons/searchIcon.svg" alt="search">
            <div class="header__search" v-if="searchDropdown" ref="searchDropdownRef">
              <div
                  @click="rediretToProduct(product.node.uuid)"
                  :key="product.node.uuid"
                  class="header__search-item"
                  v-for="product in searchProducts"
                  v-if="!isLoading && searchProducts.length"
              >
                <img class="header__search-item-img" :src="product.node.images.edges[0].node.image" :alt="product.node.name">
                <div class="header__search-inner">
                  <p class="header__search-name">{{ product.node.name }}</p>
                  <p class="header__search-price">{{ product.node.price }}€</p>
                </div>
              </div>
              <p v-if="!isLoading && !searchProducts.length" class="header__search-name">No results found.</p>
            </div>
          </div>
          <div class="header__actions">
            <div @click="redirect('/profile')" class="header__actions-inner actions">
              <svg width="24" height="24" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Dribbble-Light-Preview" transform="translate(-420.000000, -2159.000000)" fill="currentColor">
                    <g id="icons" transform="translate(56.000000, 160.000000)">
                      <path d="M374,2009 C371.794,2009 370,2007.206 370,2005 C370,2002.794 371.794,2001 374,2001 C376.206,2001 378,2002.794 378,2005 C378,2007.206 376.206,2009 374,2009 M377.758,2009.673 C379.124,2008.574 380,2006.89 380,2005 C380,2001.686 377.314,1999 374,1999 C370.686,1999 368,2001.686 368,2005 C368,2006.89 368.876,2008.574 370.242,2009.673 C366.583,2011.048 364,2014.445 364,2019 L366,2019 C366,2014 369.589,2011 374,2011 C378.411,2011 382,2014 382,2019 L384,2019 C384,2014.445 381.417,2011.048 377.758,2009.673"
                          id="profile-[#1335]">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              Account
            </div>
            <div @click="redirect('/wishlist')" class="header__actions-inner">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 6.00019C10.2006 3.90317 7.19377 3.2551 4.93923 5.17534C2.68468 7.09558 2.36727 10.3061 4.13778 12.5772C5.60984 14.4654 10.0648 18.4479 11.5249 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Wishlist
              <div v-if="user" class="header__actions-wishlist">{{ itemsInWishlist }}</div>
            </div>
            <div class="header__actions-dropdown" ref="authDropdownRef">
              <div class="header__actions-dropdown-wrapper" v-if="user">
                <router-link to="/profile" class="header__actions-dropdown-item">
                  <img src="@icons/registerIcon.svg" alt="profile">
                  MY ACCOUNT
                </router-link>
                <router-link to="/orders" class="header__actions-dropdown-item">
                  <img src="@icons/ordersIcon.svg" alt="orders">
                  MY ORDERS
                </router-link>
                <div class="header__actions-dropdown-item" @click="logout">
                  <img src="@icons/logoutIcon.svg" alt="logout">
                  SIGN OUT
                </div>
              </div>
              <div class="header__actions-dropdown-wrapper" v-else>
                <div class="header__actions-dropdown-item" @click="authModalsStore.setSignIn(true)">
                  <img src="@icons/sinInIcon.svg" alt="sign in">
                  SIGN IN
                </div>
                <div class="header__actions-dropdown-item" @click="authModalsStore.setSignUp(true)">
                  <img src="@icons/registerIcon.svg" alt="register">
                  REGISTER
                </div>
              </div>
            </div>
          </div>
          <router-link v-if="user" to="/cart" class="header__cart">
            {{ itemsInCart }} item(s) - {{ totalPrice }}€
            <img src="@icons/cartIcon.svg" alt="cart">
          </router-link>
          <div v-else class="header__cart-disabled">
            {{ itemsInCart }} item(s) - {{ totalPrice }}€
            <img src="@icons/cartIcon.svg" alt="cart">
          </div>
        </div>
      </div>
    </div>
  </header>
  <base-header-mobile />
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import { onClickOutside } from '@vueuse/core'
import {useAuthModalsStore} from "@/stores/authModals.js";
import {useRouter} from "vue-router";
import BaseHeaderMobile from "@/components/base/base-header-mobile.vue";
import {useAuthStore} from "@/stores/auth.js";
import {Categories} from "@/core/api/categories.js";
import {useCartStore} from "@/stores/cart.js";
import {useWishlistStore} from "@/stores/wishlist.js";
import {Products} from "@/core/api/products.js";

const router = useRouter()
const authStore = useAuthStore()
const authModalsStore = useAuthModalsStore()
const cartStore = useCartStore()
const wishlistStore = useWishlistStore()

const itemsInCart = computed(() => {
  let res = 0
  cartStore.currentOrder.orderProducts?.edges.map((item) => {
    res += item.node.quantity
  })
  return res
})
const itemsInWishlist = computed(() => {
  return wishlistStore.wishlist.products ? wishlistStore.wishlist.products.edges.length : 0
})
const totalPrice = computed(() => {
  return cartStore.currentOrder ? cartStore.currentOrder.totalPrice : 0
})

const search = ref('')

const categoryDropdown = ref(false)
const categoryDropdownRef = ref(null)
const searchDropdown = ref(false)
const searchDropdownRef = ref(null)

const user = computed(() => authStore.user)

const searchProducts = ref([])
const isLoading = ref(false)

const categoriesForSearch = [
  ""
]

const selectedCategoryForSearch = ref('')
const selectCategoryForSearch = (category) => {
  selectedCategoryForSearch.value = category
}

onClickOutside(categoryDropdownRef, () => categoryDropdown.value = false)
onClickOutside(searchDropdownRef, () => searchDropdown.value = false)

const redirect = (link) => {
  if (user.value) {
    router.push(link)
  } else {
    authModalsStore.setSignIn(true)
  }
}

const rediretToProduct = (id) => {
  router.push({
    name: 'product-page',
    params: { productId: id }
  })
  searchDropdown.value = false
}

const categories = ref([])

const getCategories = async () => {
  const res = await Categories.getAllCategories()
  categories.value = res.data.categories.edges
  res.data.categories.edges.map((category) => categoriesForSearch.push(category.node.name))
}

const getProductsFromSearch = async () => {
  isLoading.value = true

  const res = await Products.getAllProductsFromSearch(0, selectedCategoryForSearch.value, search.value)
  searchProducts.value = res.data.products.edges
  searchDropdown.value = true

  isLoading.value = false
}

const logout = async () => {
  await router.push({ name: 'home' })

  authStore.setUser({
    user: null,
    accessToken: null
  })

  localStorage.removeItem('stz-refresh')
}

watch(
  () => search.value,
  async () => {
    await getProductsFromSearch()
  },
  { deep: true }
)
watch(
  () => selectedCategoryForSearch.value,
  async () => {
    await getProductsFromSearch()
  },
  { deep: true }
)

onMounted(async () => {
  await getCategories()
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.header {

  &__logo {
    width: 125px;

    & img {
      width: 100%;
    }
  }

  &__top {
    padding-block: 10px;
    background-color: $white;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__contact {
    display: flex;
    gap: 20px;

    &-inner {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      
      &:hover div h6 {
        color: $light-green;
      }
    }

    &-texts {
      color: $dark-blue;

      & h6 {
        transition: 0.2s;
        font-size: 16px;
        font-weight: 500;
      }

      & p {
        font-size: 12px;
      }
    }
  }

  &__bottom {
    padding-block: 10px;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-search {
      position: relative;
      display: flex;
      align-items: center;
      width: 550px;
      height: 35px;

      & img {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
      }
    }

    &-input {
      width: 100%;
      height: 100%;
      background-color: rgba(225, 238, 250, 1);
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      padding-inline: 12px;

      font-size: 16px;
      color: $dark-blue;
    }
  }

  &__search {
    position: absolute;
    z-index: 2;
    top: 40px;
    width: 100%;
    box-shadow: 0 5px 60px -10px rgba(0,0,0,0.3);
    border: 1px solid rgba(245,245,245,1);
    border-radius: 2px;
    max-height: 350px;
    overflow-y: auto;
    background-color: $white;
    margin-left: auto;

    &-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 15px;
      padding-block: 5px;
      border-bottom: 1px solid rgba(238,238,238,1);
      background-color: $white;
      transition: 0.2s;

      &:hover {
        background-color: rgba(245,245,245,1);
      }

      &-img {
        position: unset !important;
        transform: unset !important;
        width: 60px;
        aspect-ratio: 1;
      }
    }

    &-name {
      font-size: 15px;
      font-weight: 700;
      color: #444;
    }

    &-price {
      color: #555;
      font-size: 14px;
    }

    &-button {
      cursor: pointer;
      background-color: rgba(225, 238, 250, 1);
      width: fit-content;
      height: 100%;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0;
      padding-top: 3px;
      padding-inline: 5px;
      transition: 0.2s;

      color: rgba(109, 118, 125, 1);
      font-size: 16px;

      &:hover {
        background-color: $yellow;
      }

      &:hover ~ .header__search-categories {
        display: flex;
      }
    }

    &-arrow {
      margin-bottom: 3px;
      position: unset !important;
      transform: unset !important;
    }

    &-categories {
      padding-top: 5px;
      display: none;
      position: absolute;
      top: 35px;
      z-index: 3;
      box-shadow: 0 0 85px -10px rgba(0, 0, 0, 0.2);
      max-height: 350px;
      width: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 2px;
      flex-direction: column;
      align-items: center;

      &:hover {
        display: flex;
      }

      & p {
        cursor: pointer;
        background-color: $white;
        border-bottom: 1px solid rgba(245, 245, 245, 1);
        padding: 11px;
        width: 100%;
        transition: 0.2s;

        font-size: 14px;
        font-weight: 500;
        color: rgba(35,56,81,1);

        &:hover {
          background-color: #e1eefa;
        }
      }
    }
  }

  &__categories {
    cursor: pointer;
    background-color: $yellow;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 35px;
    padding-inline: 20px;
    border-radius: 3px;
    transition: 0.2s;

    color: $dark-blue;

    &:hover {
      background-color: rgba(225,238,250,1);
    }
  }

  &__actions {
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;

    &-dropdown {
      position: absolute;
      z-index: 2;
      left: -35px;
      top: 40px;
      background-color: $white;
      border-radius: 3px;
      padding: 7px;
      box-shadow: 0 5px 60px -10px rgba(0, 0, 0, 0.3);
      display: none;
      flex-direction: column;
      gap: 5px;

      &:hover {
        display: flex;
      }

      &-item {
        cursor: pointer;
        padding: 5px 8px;
        display: flex;
        align-items: center;
        gap: 10px;
        transition: 0.2s;

        color: $dark-blue;
        font-size: 13px;

        &:hover {
          background-color: rgba(245, 245, 245, 1);
          color: rgba(35, 56, 81, 1)
        }

        &-category {
          cursor: pointer;
          padding: 11px;
          display: flex;
          align-items: center;
          gap: 10px;
          border-bottom: 1px solid rgba(245, 245, 245, 1);
          transition: 0.2s;

          color: $dark-blue;
          font-size: 14px;

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            color: rgba(35, 56, 81, 1);
            background-color: rgba(225, 238, 250, 1);
          }

          & img {
            width: 25px;
          }
        }
      }
    }

    &-inner {
      transition: 0.2s;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;

      font-size: 12px;
      color: $grey;

      & img {
        width: 24px;
      }

      &:hover{
        color: $light-blue;
      }
    }

    &-wishlist {
      position: absolute;
      right: -3px;
      top: -7px;
      border-radius: 50%;
      width: 18px;
      aspect-ratio: 1;
      display: grid;
      place-items: center;
      background-color: $red;

      color: $white;
      font-size: 10px;
    }
  }

  &__dropdown {
    &-item {
      cursor: pointer;
      padding: 5px 8px;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: 0.2s;

      color: $dark-blue;
      font-size: 13px;

      &:hover {
        background-color: rgba(245, 245, 245, 1);
        color: rgba(35, 56, 81, 1)
      }

      &-category {
        cursor: pointer;
        padding: 11px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid rgba(245, 245, 245, 1);
        transition: 0.2s;

        color: $dark-blue;
        font-size: 14px;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          color: rgba(35, 56, 81, 1);
          background-color: rgba(225, 238, 250, 1);
        }

        & img {
          width: 25px;
        }
      }
    }

    &-category {
      width: 100%;
      position: absolute;
      z-index: 2;
      left: 0;
      top: 50px;
      background-color: $white;
      border-radius: 3px;
      box-shadow: 0 5px 60px -10px rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
    }
  }

  &__cart {
    cursor: pointer;
    background-color: $yellow;
    border-radius: 3px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: 0.2s;

    font-size: 14px;
    color: $dark-blue;

    &:hover {
      background-color: rgba(225,238,250,1);
    }

    &-disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
      border-radius: 3px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: 0.2s;

      font-size: 14px;
      color: $dark-blue;
    }
  }

  &__left {
    position: relative;
  }
}

.actions {
  &:hover ~ .header__actions-dropdown {
    display: flex;
  }
}

@media (max-width: 1250px) {
  .header {
    &__bottom {
      &-search {
        width: 300px;
      }
    }
  }
}

@media (max-width: 900px) {
  .header {
    display: none;
  }
}
</style>
import {apiUrl} from "@/core/api/index.js";

export class Products {
  static async getAllProducts(after, minPrice, maxPrice, orderBy, categories, tags, first, sizes, colors) {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `{
          products(after: "${after}", minPrice: "${minPrice}", maxPrice: "${maxPrice}", orderBy: "${orderBy}", categories: "${categories}", tags: "${tags}", first: ${first}, size: "${sizes}", color: "${colors}") {
            edges {
              cursor
              node {
                uuid
                name
                description
                attributes
                images {
                  edges {
                    node {
                      image
                    }
                  }
                }
                price
              }
            }
          }
        }`
      })
    });
    return await response.json();
  }

  static async getAllProductsFromSearch(after, category, name) {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `{
          products(after: "0", categories: "${category}" first: 10, name: "${name}") {
            edges {
              node {
                uuid
                name
                price
                images {
                  edges {
                    node {
                      image
                    }
                  }
                }
              }
            }
          }
        }`
      })
    });
    return await response.json();
  }

  static async getProductByTag(tag) {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `{
          products(tags: "${tag}") {
            edges {
              node {
                uuid
                name
                description
                attributes
                images {
                  edges {
                    node {
                      image
                    }
                  }
                }
                price
              }
            }
          }
        }`
      })
    });
    return await response.json();
  }

  static async getProductById(id) {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `{
          products(uuid: "${id}") {
            edges {
              node {
                name
                description
                attributes
                price
                category {
                  name
                }
                images {
                  edges {
                    node {
                      image
                      uuid
                    }
                  }
                }
              }
            }
          }
        }`
      })
    });
    return await response.json();
  }

  static async getProductsByTagForStore(after, tag, first) {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `{
          products(after: "${after}", tags: "${tag}", first: ${first}) {
            edges {
              node {
                uuid
                name
                description
                attributes
                images {
                  edges {
                    node {
                      image
                    }
                  }
                }
                price
              }
            }
          }
        }`
      })
    });
    return await response.json();
  }
}
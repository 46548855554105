<script setup>
import {RouterView} from 'vue-router'
import BaseHeader from "@/components/base/base-header.vue";
import BaseFooter from "@/components/base/base-footer.vue";
import AuthBase from "@/components/base/auth-base.vue";
import LoginForm from "@/components/forms/login-form.vue";
import {computed, onMounted} from "vue";
import {useAuthModalsStore} from "@/stores/authModals.js";
import RegisterForm from "@/components/forms/register-form.vue";
import {Auth} from "@/core/api/auth.js";
import {useAuthStore} from "@/stores/auth.js";
import {Orders} from "@/core/api/order.js";
import {useCartStore} from "@/stores/cart.js";
import ProductDetails from "@/components/product/product-details.vue";
import {useAppStore} from "@/stores/app.js";
import {apiUrl} from "@/core/api/index.js";
import {useCompanyStore} from "@/stores/company.js";
import ProductAttributes from "@/components/product/product-attributes.vue";
import UiPopup from "@/components/UI/ui-popup.vue";
import {useAddressStore} from "@/stores/address.js";
import {Address} from "@/core/api/address.js";

const authModalsStore = useAuthModalsStore()
const authStore = useAuthStore()
const cartStore = useCartStore()
const appStore = useAppStore()
const companyStore = useCompanyStore()
const addressStore = useAddressStore()

const isSignUp = computed(() => authModalsStore.isSignUp)
const isSignIn = computed(() => authModalsStore.isSignIn)

const popupArray = computed(() => appStore.popupArray)

const checkUserAuth = async () => {
  const authResult = await Auth.Authenticate()

  if (authResult.data) {
    authStore.setUser({
      user: authResult.data.refreshJwtToken.user,
      accessToken: authResult.data.refreshJwtToken.accessToken
    })

    localStorage.setItem('stz-refresh', authResult.data.refreshJwtToken.refreshToken)

    const orderResult = await Orders.getPendingOrder(
      authResult.data.refreshJwtToken.accessToken,
      { userEmail: authResult.data.refreshJwtToken.user.email }
    );
    cartStore.setCurrentOrders(orderResult.data.orders.edges[0].node)

    const addressResult = await Address.getAddresses(authResult.data.refreshJwtToken.accessToken)
    addressStore.setAddress(addressResult.data.addresses.edges)
  }
}

const getCompanyInfo = async () => {
  const res = await fetch(`${apiUrl}`, {
    method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `{
          config {
            companyName
            companyEmail
            companyAddress
            companyPhone
            paymentGatewayUrl
            projectName
          }
        }`
      })
  })
  const info = await res.json()

  if (res.status === 200) {
    companyStore.setCompanyInfo(info.data.config)
  }
}

onMounted(async () => {
  await getCompanyInfo()
  await checkUserAuth()

  setInterval(async () => {
    await getCompanyInfo()
    await checkUserAuth()
  }, 470000)
})
</script>

<template>
  <base-header />
  <div class="popups">
    <transition-group name="slide-fade">
      <ui-popup
        v-for="(popup, idx) of popupArray"
        :key="idx"
        v-if="appStore.isPopupVisible"
        :popup="popup"
      />
    </transition-group>
  </div>
  <Transition>
    <product-details :productId="appStore.activeProductId" v-if="appStore.isProductDetailsVisible" />
  </Transition>
  <Transition>
    <product-attributes :product="appStore.activeProductAttributes" v-if="appStore.isProductAttributesVisible" />
  </Transition>
  <Transition>
    <auth-base v-if="isSignUp || isSignIn">
      <login-form v-if="isSignIn" />
      <register-form v-if="isSignUp" />
    </auth-base>
  </Transition>
  <RouterView v-slot="{ Component }">
    <Transition name="page-opacity" mode="out-in">
      <component :is="Component" />
    </Transition>
  </RouterView>
  <base-footer />
</template>

<style>
@import './assets/styles/global/variables.scss';

.page-opacity-enter-active,
.page-opacity-leave-active {
    transition: 300ms ease all;
}
.page-opacity-enter-from,
.page-opacity-leave-to {
    opacity: 0;
}
.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}
.v-enter-from,
.v-leave-to {
    opacity: 0;
}
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

#app {
  min-height: 100vh;
  position: relative;
}

.el-collapse-item__header {
  font-weight: 700 !important;
  font-size: 12px !important;
  color: rgba(109, 118, 125, 1) !important;
}

.el-slider__bar {
  background-color: #8FD2EE !important;
}

.el-slider__button {
  background-color: #8FD2EE !important;
  border: none !important;
  width: 20px !important;
  height: 20px !important;
}

.popups {
  position: fixed;
  z-index: 100;
  top: 115px;
  right: 20px;
  max-width: 400px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 35px;
}

@media (max-width: 450px) {
  .popups {
    max-width: 285px;
  }
}
</style>

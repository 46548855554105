<template>
  <div class="page">
    <h1 class="page__title">Checkout</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__addresses">
          <div class="page__addresses-inner">
            <p class="page__subtitle">Billing Address</p>
            <el-select
              v-model="selectedBillingAddress"
              placeholder="Addresses"
            >
              <el-option
                v-for="item in addressesList"
                :key="item.uuid"
                :label="item.address"
                :value="item.uuid"
              />
            </el-select>
            <router-link :to="({ name: 'add-address' })" class="page__link">I want to use a new address</router-link>
            <label class="page__label">
              <input type="checkbox" v-model="isAddressSame">
              My delivery and billing addresses are the same.
            </label>
          </div>
          <div class="page__addresses-inner" v-if="!isAddressSame">
            <p class="page__subtitle">Shipping Address</p>
            <el-select
              v-model="selectedShippingAddress"
              placeholder="Addresses"
            >
              <el-option
                v-for="item in addressesList"
                :key="item.uuid"
                :label="item.address"
                :value="item.uuid"
              />
            </el-select>
            <router-link :to="({ name: 'add-address' })" class="page__link">I want to use a new address</router-link>
          </div>
        </div>
        <div class="page__main">
          <div class="page__main-inner">
            <div class="page__method">
              <p class="page__subtitle">Shipping Method</p>
              <div class="page__method-inner">
                <img src="@icons/shippingIcon.svg" alt="shipping">
                <label class="page__label">
                  <input
                    type="radio"
                    name="subscribe"
                    value="flat"
                    v-model="selectedShipping"
                  >
                  Flat Shipping Rate - 5.00€
                </label>
              </div>
            </div>
          </div>
          <div class="page__main-wrapper">
            <p class="page__subtitle">Coupon / Voucher</p>
            <div class="page__main-block">
              Enter your coupon here
              <div class="page__main-right">
                <input
                  type="text"
                  class="page__input"
                  placeholder="Enter your coupon here"
                >
                <button class="page__button">SUBMIT</button>
              </div>
            </div>
            <div class="page__main-block">
              Enter your gift certificate code here
              <div class="page__main-right">
                <input
                  type="text"
                  class="page__input"
                  placeholder="Enter your gift certificate code here"
                >
                <button class="page__button">SUBMIT</button>
              </div>
            </div>
          </div>
          <div class="page__main-wrapper">
            <p class="page__subtitle">Shopping Cart</p>
            <div class="page__inner">
              <table class="page__table">
              <thead>
                <tr>
                  <th>IMAGE</th>
                  <th>PRODUCT NAME</th>
                  <th>QUANTITY</th>
                  <th>UNIT PRICE</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <product-in-cart
                  v-for="product in products"
                  :key="product.node.product.uuid"
                  :product="product"
                />
              </tbody>
            </table>
            </div>
            <div class="page__main-bottom">
              <p class="page__main-text">Sub-Total: <span>{{ totalPrice }}€</span></p>
              <p class="page__main-text">Flat Shipping Rate: <span>5€</span></p>
              <p class="page__main-text">Total: <span>{{ totalPrice + 5 }}€</span></p>
            </div>
          </div>
          <div class="page__main-wrapper">
            <p class="page__subtitle">Payment Details</p>
            <p class="page__bigtext">Card Payment</p>
            <div class="page__texts">
              <p class="page__texts-text">Your payment will be made in favor of the company:</p>
              <p class="page__texts-text">{{ companyInfo?.companyName }}</p>
              <p class="page__texts-text">{{ companyInfo?.companyAddress }}</p>
            </div>
          </div>
          <div class="page__main-wrapper">
            <p class="page__subtitle">Confirm Your Order</p>
            <textarea
              class="page__textarea"
              placeholder="Add Comments About Your Order"
              v-model="comment"
            />
            <label class="page__label">
              <input
                type="checkbox"
                v-model="isAgreed"
              >
              I have read and agree to the <router-link class="page__link" :to="({ name: 'terms' })">Terms of use</router-link>
            </label>
            <div class="page__line"></div>
            <button
              class="page__button"
              :disabled="!isValid"
              @click="buyOrder"
            >
              CONFIRM ORDER
              <img src="@icons/arrowIcon.svg" alt="confirm">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useAddressStore} from "@/stores/address.js";
import {computed, onMounted, reactive, ref} from "vue";
import ProductInCart from "@/components/product/product-in-cart.vue";
import {useCartStore} from "@/stores/cart.js";
import {useCompanyStore} from "@/stores/company.js";
import {required} from "@/core/rules/textFieldRules.js";
import {Orders} from "@/core/api/order.js";
import {useAuthStore} from "@/stores/auth.js";
import {useAppStore} from "@/stores/app.js";

const authStore = useAuthStore()
const cartStore = useCartStore()
const addressStore = useAddressStore()
const appStore = useAppStore()
const companyStore = useCompanyStore()

const selectedBillingAddress = ref('')
const selectedShippingAddress = computed(() => {
  return isAddressSame ? selectedBillingAddress.value : ''
})
const addressesList = []
const isAddressSame = ref(true)
const isAgreed = ref(false)
const selectedShipping = ref('flat')
const comment = ref('')

const companyInfo = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo : null
})

const cartId = computed(() => {
  return cartStore.currentOrder ? cartStore.currentOrder.uuid : null
})

const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})
const userBalance = computed(() => {
  return authStore.user ? authStore.user.balance.amount : null
})

const totalPrice = computed(() => {
  return cartStore.currentOrder ? cartStore.currentOrder.totalPrice : 0
})

const products = computed(() => {
  return cartStore.currentOrder.orderProducts ? cartStore.currentOrder.orderProducts.edges.map((product) => {
    return reactive({
      node: product.node,
      stepper: product.node.quantity,
    });
  }) : [];
})

const isValid = computed(() => {
  return (
    required(selectedBillingAddress.value) === true &&
    isAgreed.value
  )
})

const getAddresses = () => {
  addressStore.addresses.forEach((address) => {
    addressesList.push({ address: `${address.node.street}, ${address.node.city.name}, ${address.node.region.name}, ${address.node.country.name}`, uuid: address.node.uuid })
  })
}

const buyOrder = async () => {
  const res = await Orders.buyOrder(accessToken.value, selectedBillingAddress.value, selectedShippingAddress.value, cartId.value)

  if (userBalance.value < totalPrice.value + 5) {
    if (res.data.buyOrder.transaction.process) {
      window.location.href = res.data.buyOrder.transaction.process.redirect_url
    } else {
      const popup = {
        text: 'Something went wrong!',
        tag: 'fail',
        id: Math.random() * 2000
      }
      appStore.popupArray.unshift(popup)
      appStore.setPopupVisible(true)
    }
  } else {
    if (res.data.buyOrder.order.status) {
      const popup = {
        text: 'Checkout was successfull!',
        tag: 'success',
        id: Math.random() * 2000
      }
      appStore.popupArray.unshift(popup)
      appStore.setPopupVisible(true)
    } else {
      const popup = {
        text: 'Something went wrong!',
        tag: 'fail',
        id: Math.random() * 2000
      }
      appStore.popupArray.unshift(popup)
      appStore.setPopupVisible(true)
    }
  }
}

onMounted(() => {
  getAddresses()
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    gap: 50px;
  }

  &__inner {
    width: 100%;
    overflow: auto;
  }

  &__addresses {
    width: 384px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 25px;

    &-inner {
      width: 100%;
      background-color: rgba(245, 245, 245, 1);
      padding: 15px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  &__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;

    &-inner {
      background-color: rgba(245, 245, 245, 1);
      padding: 15px;
      border-radius: 3px;
      display: flex;
      align-items: center;
    }

    &-wrapper {
      background-color: rgba(245, 245, 245, 1);
      padding: 15px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &-block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 13px;
      color: rgba(35,56,81,1);
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &-bottom {
      background-color: #eeeeee;
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-text {
      margin-left: 60%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-weight: 700;
      font-size: 16px;
      color: rgba(35,56,81,1);

      & span {
        font-weight: 400;
      }
    }
  }

  &__input {
    width: 400px;
    height: 36px;
    padding-left: 12px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(238, 238, 238, 1);
    border-radius: 2px;

    font-size: 14px;
    color: #555;
  }

  &__textarea {
    resize: none;
    width: 100%;
    height: 100px;
    padding: 12px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(238, 238, 238, 1);
    border-radius: 2px;

    font-size: 14px;
    color: #555;
  }

  &__button {
    align-self: flex-end;
    cursor: pointer;
    width: fit-content;
    height: 36px;
    padding-inline: 15px;
    background-color: $blue;
    transition: 0.2s;
    display: flex;
    align-items: center;
    gap: 3px;

    font-size: 12px;
    color: $white;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }

  &__method {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-inner {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__link {
    text-decoration: underline;
    font-size: 13px;
    font-weight: 500;
    color: rgba(35,56,81,1);
  }

  &__label {
    cursor: pointer;
    font-size: 13px;
    color: rgba(35,56,81,1);
  }

  &__bigtext {
    font-size: 40px;
    font-weight: 700;
    color: rgba(35,56,81,1);
  }

  &__texts {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 3px;

    &-text {
      font-weight: 400;
      font-size: 16px;
      color: rgba(35,56,81,1);
    }
  }

  &__table {
    width: 100%;
    border: 1px solid rgba(221,221,221,1);
    border-collapse: collapse;

    & th {
      padding: 8px 14px;
    }

    & tr {
      border-bottom: 1px solid rgba(221,221,221,1);
    }
  }

  &__subtitle {
    text-transform: uppercase;
    color: $dark-blue;
    font-weight: 700;
    font-size: 16px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(35,56,81,1);
  }

  &__line {
    margin-top: 25px;
    width: 100%;
    height: 1px;
    background-color: rgba(221, 221, 221, 1);
  }
}

@media (max-width: 1200px) {
  .page {
    &__wrapper {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 750px) {
  .page {
    &__main {
      &-block {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
      }

      &-text {
        margin-left: 40%;
      }
    }

    &__input {
      width: 200px;
    }
  }
}

@media (max-width: 400px) {
  .page {
    &__addresses {
      width: 300px;
    }
  }
}
</style>
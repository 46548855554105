<template>
  <div class="forgot">
    <p class="forgot__text">Enter the e-mail address associated with your account. Click submit to have a password reset link e-mailed to you.</p>
    <div class="forgot__form">
      <p class="forgot__title">YOUR E-MAIL ADDRESS</p>
      <div class="forgot__block">
        <div class="forgot__name required">E-Mail Address</div>
        <div class="forgot__inner">
          <input
              type="email"
              placeholder="E-Mail Address"
              class="forgot__block-input"
              v-model="email"
              @input="onEmailInput"
          >
          <p class="forgot__block-error" v-if="emailError">{{ emailError }}</p>
        </div>
      </div>
    </div>
    <div class="forgot__line"></div>
    <div class="forgot__buttons">
      <button @click="back" class="forgot__button red">
        <img class="rotate" src="@icons/arrowIcon.svg" alt="back">
        Back
      </button>
      <button
          class="forgot__button"
          @click="resetPassword"
          :disabled="!isFormValid"
      >
        Continue
        <img src="@icons/arrowIcon.svg" alt="continue">
      </button>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import {computed, ref} from "vue";
import {isEmail} from "@/core/rules/textFieldRules.js";
import {Auth} from "@/core/api/auth.js";
import {useAppStore} from "@/stores/app.js";

const appStore = useAppStore()
const router = useRouter()

const back = () => {
  router.go(-1)
}

const email = ref('')
const emailError = ref('')

const onEmailInput = (e) => {
  if (isEmail(e.target.value) !== true) {
    emailError.value = isEmail(e.target.value)
  } else {
    emailError.value = ""
  }
}

const isFormValid = computed(() => {
  return (
    isEmail(email.value) === true
  )
})

const resetPassword = async () => {
  const res = await Auth.ResetPassword(email.value)

  if (res.data.resetPassword.success) {
    const popup = {
      text: 'Email was sent successfully! Check your inbox!',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.forgot {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
  padding-block: 20px;

  &__text {
    font-size: 16px;
    color: rgba(35,56,81,1);
  }

  &__title {
    color: rgba(35,56,81,1);
    font-weight: 700;
    font-size: 15px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__block {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 50px;

    &-input {
      width: 320px;
      height: 34px;
      border: 1px solid rgba(238, 238, 238, 1);
      padding-inline: 12px;

      color: #555;
      font-size: 14px;

      &:hover {
        border-color: rgba(221, 221, 221, 1);
      }
    }

    &-error {
      margin-top: 5px;
      color: $red;
      font-size: 12px;
    }
  }

  &__name {
    margin-top: 10px;
    display: flex;
    gap: 3px;

    font-size: 13px;
    color: rgba(35,56,81,1);
  }

  &__line {
    height: 1px;
    width: 100%;
    background-color: rgba(221, 221, 221, 1);
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    cursor: pointer;
    align-self: flex-end;
    background-color: $blue;
    display: flex;
    align-items: center;
    gap: 3px;
    transition: 0.2s;
    border-radius: 3px;
    padding: 12px 15px;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }
}

.rotate {
  transform: rotate(0.5turn);
}

.red {
  background-color: $red;

  &:hover {
    background-color: $light-blue;
  }
}

.required {

  &::after {
    content: "*";
    color: rgba(232, 40, 77, 1);
  }
}
</style>
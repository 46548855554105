import {defineStore} from "pinia";
import {ref, watch} from "vue";
import {Wishlist} from "@/core/api/wishlist.js";
import {useWishlistStore} from "@/stores/wishlist.js";
import {Address} from "@/core/api/address.js";
import {useAddressStore} from "@/stores/address.js";

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null)
  const accessToken = ref(null)

  const setUser = (payload) => {
    accessToken.value = payload.accessToken
    user.value = payload.user
  }

  watch(
    () => user.value,
    async () => {
      if (accessToken.value) {
        const wishlistStore = useWishlistStore()
        const userWishlist = await Wishlist.getWishlist(user.value.uuid, accessToken.value)
        wishlistStore.wishlist = userWishlist.data.users.edges[0].node.wishlist

        const addressStore = useAddressStore()
        const addressResult = await Address.getAddresses(accessToken.value)
        addressStore.setAddress(addressResult.data.addresses.edges)
      }
    },
    { deep: true }
  )

  return { user, accessToken, setUser }
})
<template>
  <div class="popup">
    <svg v-if="popup.tag === 'success'" width="63.599609" height="63.600098" viewBox="0 0 63.5996 63.6001" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter id="filter_8_36_dd" x="0.000000" y="0.000000" width="63.599609" height="63.600098" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="0" dy="0"/>
          <feGaussianBlur stdDeviation="2.1"/>
          <feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
        </filter>
        <clipPath id="clip8_36">
          <rect id="Icon" rx="14.000000" width="51.000000" height="51.000000" transform="translate(6.299805 6.300049)" fill="white" fill-opacity="0"/>
        </clipPath>
      </defs>
      <g filter="url(#filter_8_36_dd)">
        <rect id="Icon" rx="14.000000" width="51.000000" height="51.000000" transform="translate(6.299805 6.300049)" fill="#FFFFFF" fill-opacity="1.000000"/>
        <g clip-path="url(#clip8_36)">
          <path id="Vector" d="M42.19 41.19C44.73 38.66 46.29 35.16 46.29 31.3C46.29 27.43 44.73 23.93 42.19 21.4C39.66 18.86 36.16 17.29 32.29 17.29C28.43 17.29 24.93 18.86 22.4 21.4C19.86 23.93 18.29 27.43 18.29 31.3C18.29 35.16 19.86 38.66 22.4 41.19C24.93 43.73 28.43 45.3 32.29 45.3C36.16 45.3 39.66 43.73 42.19 41.19ZM26.69 31.3L30.89 35.5L39.29 27.1" stroke="#F278DF" stroke-opacity="1.000000" stroke-width="3.000000" stroke-linejoin="round" stroke-linecap="round"/>
        </g>
        <rect id="Icon" rx="14.000000" width="51.000000" height="51.000000" transform="translate(6.299805 6.300049)" stroke="#FFE27D" stroke-opacity="0" stroke-width="2.000000"/>
      </g>
    </svg>
    <svg v-else width="63.599609" height="63.600098" viewBox="0 0 63.5996 63.6001" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="filter_8_96_dd" x="0.000000" y="0.000000" width="63.599609" height="63.600098" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="0" dy="0"/>
          <feGaussianBlur stdDeviation="2.1"/>
          <feComposite in2="hardAlpha" operator="out" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect_dropShadow_1"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect_dropShadow_1" result="shape"/>
        </filter>
        <clipPath id="clip8_96">
          <rect id="Icon" rx="14.000000" width="51.000000" height="51.000000" transform="translate(6.299805 6.300049)" fill="white" fill-opacity="0"/>
        </clipPath>
      </defs>
      <g filter="url(#filter_8_96_dd)">
        <rect id="Icon" rx="14.000000" width="51.000000" height="51.000000" transform="translate(6.299805 6.300049)" fill="#FFFFFF" fill-opacity="1.000000"/>
        <g clip-path="url(#clip8_96)">
          <path id="Vector" d="M42.19 41.19C44.73 38.66 46.29 35.16 46.29 31.3C46.29 27.43 44.73 23.93 42.19 21.4C39.66 18.86 36.16 17.29 32.29 17.29C28.43 17.29 24.93 18.86 22.4 21.4C19.86 23.93 18.29 27.43 18.29 31.3C18.29 35.16 19.86 38.66 22.4 41.19C24.93 43.73 28.43 45.3 32.29 45.3C36.16 45.3 39.66 43.73 42.19 41.19ZM22.34 22.74L41.54 40.74M41.85 22.74L22.65 40.74" stroke="#808080" stroke-opacity="1.000000" stroke-width="3.000000"/>
        </g>
        <rect id="Icon" rx="14.000000" width="51.000000" height="51.000000" transform="translate(6.299805 6.300049)" stroke="#FFE27D" stroke-opacity="0" stroke-width="2.000000"/>
      </g>
    </svg>
    <p class="popup__text"><span>{{ popup.tag === 'success' ? 'Success!' : 'Oops!' }}</span> {{ popup.text }}</p>
    <div class="popup__line" :class="[{ success: popup.tag === 'success' }]"></div>
  </div>
</template>

<script setup>
import {computed, watch} from "vue";
import {useAppStore} from "@/stores/app.js";

const appStore = useAppStore()
const props = defineProps({
    popup: Object
})

const computedId = computed(() => props.popup.id)

watch(
  () => computedId.value,
  () => {
    setTimeout(() => {
      appStore.popupArray = appStore.popupArray.filter((el) => el.id !== computedId.value)
    }, 5000)
  },
  { deep: true, immediate: true }
)
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.popup {
  position: relative;
  width: 100%;
  background-color: $white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 20px;
  box-shadow: 0 0 50px 5px #353535;

  &__text {
    font-size: 14px;
    font-weight: 400;
    color: #43484D;

    & span {
      font-weight: 500;
      text-decoration: underline;
    }
  }

  &__line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: linear-gradient(90.00deg, rgb(181, 181, 181) 3.938%,rgb(140, 140, 140) 49.3%,rgb(82, 82, 81) 96.612%);
  }
}

.success {
  background: linear-gradient(90.00deg, rgb(242, 120, 223) 3.938%,rgb(128, 208, 219) 49.3%,rgb(242, 230, 120) 96.612%);
}

@media (max-width: 450px) {
  .popup {
    padding: 10px 15px;
  }
}
</style>
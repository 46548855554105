import {defineStore} from "pinia";
import {ref, watch} from "vue";

export const useCartStore = defineStore('cart', () => {
  const currentOrder = ref([])
  const setCurrentOrders = (order) => {
    currentOrder.value = order
  }

  watch(
    () => currentOrder.value,
    (val) => {
      const res = []

      Object.values(currentOrder.value.orderProducts.edges).forEach(cart => {
        res.push({
          uuid: cart.node.product.uuid,
          quantity: cart.node.quantity
        })
      });

      productsInCart.value = res
    },
    { deep: true }
  )

  const productsInCart = ref([])
  const setProductsInCart = (products) => {
    productsInCart.value = products
  }

  return {
    currentOrder,
    setCurrentOrders,

    productsInCart,
    setProductsInCart
  }
})
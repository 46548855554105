<template>
  <div class="page">
    <h1 class="page__title">Product Returns</h1>
    <div class="container">
      <div class="page__wrapper">
        <p class="page__text">You have not made any previous returns!</p>
        <div class="page__line"></div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-top: 20px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(35,56,81,1);
  }

  &__line {
    margin-top: 25px;
    width: 100%;
    height: 1px;
    background-color: rgba(221, 221, 221, 1);
  }
}
</style>
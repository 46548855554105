import {defineStore} from "pinia";
import {ref, watch} from "vue";

export const useAuthModalsStore = defineStore('authModals', () => {
  const isSignUp = ref(false)
  const setSignUp = (state) => {
    isSignUp.value = state
  }

  const isSignIn = ref(false)
  const setSignIn = (state) => {
    isSignIn.value = state
  }

  watch(isSignUp, (newVal) => {
    if (newVal) {
      isSignIn.value = false;
    }
  });
  watch(isSignIn, (newVal) => {
    if (newVal) {
      isSignUp.value = false;
    }
  });

  return {
    isSignUp,
    setSignUp,

    isSignIn,
    setSignIn
  }
})
<template>
  <div class="page">
    <h1 class="page__title">Order History</h1>
    <div class="container">
      <div class="page__wrapper">
        <div v-if="orders.length" class="page__inner">
          <table class="page__table">
          <thead>
            <tr>
              <th>ORDER ID</th>
              <th>NO. OF PRODUCTS</th>
              <th>STATUS</th>
              <th>TOTAL</th>
              <th>DATE ADDED</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in orders"
              :key="order.node.uuid"
            >
              <td>{{ order.node.uuid }}</td>
              <td>{{ order.node.totalQuantity }}</td>
              <td>{{ order.node.status }}</td>
              <td>{{ order.node.totalPrice }}€</td>
              <td>{{ order.node.buyTime ? new Date(order.node.buyTime).toLocaleDateString() : '-' }}</td>
              <td><router-link :to="({ name: 'order-details', params: { orderId: order.node.uuid } })" class="page__button"><img src="@icons/eyeIcon.svg" alt="show"></router-link></td>
            </tr>
          </tbody>
        </table>
        </div>
        <p v-else class="page__text">You have not made any previous orders!</p>
        <div class="page__line"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Orders} from "@/core/api/order.js";
import {computed, onMounted, ref} from "vue";
import {useAuthStore} from "@/stores/auth.js";

const authStore = useAuthStore()

const accessToken = computed(() => {
  return authStore?.accessToken ? authStore?.accessToken : null
})
const userEmail = computed(() => {
  return authStore.user ? authStore.user.email : null
})

const isLoading = ref(false)
const orders = ref([])

const getAllOrders = async () => {
  isLoading.value = true

  const res = await Orders.getAllOrders(
    accessToken.value,
    { userEmail: userEmail.value }
  )
  orders.value = res.data.orders.edges

  isLoading.value = false
}

onMounted(async () => {
  setTimeout( async () => {
    await getAllOrders()
  }, 250)
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-top: 20px;
  }

  &__inner {
    width: 100%;
    overflow: auto;
  }

  &__table {
    width: 100%;
    border: 1px solid rgba(221,221,221,1);
    border-collapse: collapse;

    & tr {
      border-bottom: 1px solid rgba(221,221,221,1);
    }

    & th {
      padding: 8px 14px;
    }

    & td {
      text-align: center;
      padding: 5px;
    }

    &-text {
      text-align: center;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;

      & div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    &-color {
      width: 15px;
      height: 20px;
      border: 2px solid rgba(221, 221, 221, 1);
    }

    &-buttons {
      height: 97px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    &-button {
      cursor: pointer;
      width: 36px;
      aspect-ratio: 1;
      display: grid;
      place-items: center;
      background-color: $blue;
      border-radius: 2px;
      transition: 0.2s;
      color: $white;

      & img {
        width: 25px;
        aspect-ratio: 1;
      }

      &:hover {
        background-color: $orange;
      }

      &:last-child {
        background-color: $red;

        &:hover {
          background-color: $light-blue;
        }
      }
    }
  }

  &__button {
    cursor: pointer;
    background-color: $dark-grey;
    width: 35px;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    border-radius: 3px;
    transition: 0.2s;

    &:hover {
      background-color: $light-blue;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(35,56,81,1);
  }

  &__line {
    margin-top: 25px;
    width: 100%;
    height: 1px;
    background-color: rgba(221, 221, 221, 1);
  }
}
</style>
<template>
  <div class="update">
    <p class="update__title">Your Personal Details</p>
    <div class="update__form">
      <div class="update__block">
        <div class="update__name required">First Name</div>
        <div class="update__block-inner">
          <input
              type="text"
              placeholder="First Name"
              class="update__block-input"
              v-model="firstName"
              @input="onFirstNameInput"
          >
          <p class="update__block-error" v-if="firstNameError">{{ firstNameError }}</p>
        </div>
      </div>
      <div class="update__block">
        <div class="update__name required">Last Name</div>
        <div class="update__block-inner">
          <input
              type="text"
              placeholder="Last Name"
              class="update__block-input"
              v-model="lastName"
              @input="onLastNameInput"
          >
          <p class="update__block-error" v-if="lastNameError">{{ lastNameError }}</p>
        </div>
      </div>
      <div class="update__block">
        <div class="update__name required">E-Mail</div>
        <div class="update__block-inner">
          <input
              type="email"
              placeholder="E-Mail"
              class="update__block-input"
              v-model="email"
              @input="onEmailInput"
          >
          <p class="update__block-error" v-if="emailError">{{ emailError }}</p>
        </div>
      </div>
      <div class="update__block">
        <div class="update__name required">Telephone</div>
        <div class="update__block-inner">
          <input
              type="text"
              placeholder="Telephone"
              class="update__block-input"
              v-model="phoneNumber"
              @input="onPhoneInput"
          >
          <p class="update__block-error" v-if="phoneNumberError">{{ phoneNumberError }}</p>
        </div>
      </div>
    </div>
    <div class="update__line"></div>
    <div class="update__buttons">
      <button @click="back" class="update__button red">
        <img class="rotate" src="@icons/arrowIcon.svg" alt="back">
        BACK
      </button>
      <button
          class="update__button"
          @click="updateInfo"
          :disabled="!isFormValid"
      >
        CONTINUE
        <img src="@icons/arrowIcon.svg" alt="continue">
      </button>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {isEmail, required} from "@/core/rules/textFieldRules.js";
import {useAuthStore} from "@/stores/auth.js";
import {useRouter} from "vue-router";
import {Auth} from "@/core/api/auth.js";
import {useAppStore} from "@/stores/app.js";

const router = useRouter()
const authStore = useAuthStore()
const appStore = useAppStore()

const userUuid = computed(() => {
  return authStore.user ? authStore.user.uuid : null
})
const userAccessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})
const userFirstName = computed(() => {
  return authStore.user ? authStore.user.firstName : ''
})
const userLastName = computed(() => {
  return authStore.user ? authStore.user.lastName : ''
})
const userEmail = computed(() => {
  return authStore.user ? authStore.user.email : ''
})
const userPhone = computed(() => {
  return authStore.user ? authStore.user.phoneNumber : ''
})

const firstName = ref(userFirstName.value)
const lastName = ref(userLastName.value)
const email = ref(userEmail.value)
const phoneNumber = ref(userPhone.value)

const firstNameError = ref('')
const lastNameError = ref('')
const emailError = ref('')
const phoneNumberError = ref('')

const onFirstNameInput = (e) => {
  if (required(e.target.value) !== true) {
    firstNameError.value = required(e.target.value)
  } else {
    firstNameError.value = ""
  }
}
const onLastNameInput = (e) => {
  if (required(e.target.value) !== true) {
    lastNameError.value = required(e.target.value)
  } else {
    lastNameError.value = ""
  }
}
const onEmailInput = (e) => {
  if (isEmail(e.target.value) !== true) {
    emailError.value = isEmail(e.target.value)
  } else {
    emailError.value = ""
  }
}
const onPhoneInput = (e) => {
  if (required(e.target.value) !== true) {
    phoneNumberError.value = required(e.target.value)
  } else {
    phoneNumberError.value = ""
  }
}

const isFormValid = computed(() => {
  return (
    required(firstName.value) &&
    required(lastName.value) &&
    required(phoneNumber.value) &&
    isEmail(email.value) === true
  )
})

const back = () => {
  router.go(-1)
}

const updateInfo = async () => {
  const res = await Auth.UpdateData(
    userAccessToken.value,{
    query: `mutation {
      updateUser(
        uuid: "${userUuid.value}"
        firstName: "${firstName.value}"
        lastName: "${lastName.value}"
        email: "${email.value}"
        phoneNumber: "${phoneNumber.value}"
      ) {
        user {
          uuid
          firstName
          lastName
          email
          phoneNumber
          isSubscribed
          balance {
            amount
          }
        }
      }
    }`
  })

  if (res.data.updateUser) {
    const popup = {
      text: 'Your account has been successfully updated.',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)

    authStore.setUser({
      user: res.data.updateUser.user,
      accessToken: res.data.updateUser.accessToken
    })
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.update {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;

  &__title {
    text-transform: uppercase;
    color: $dark-blue;
    font-weight: 700;
    font-size: 16px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__form {
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  &__block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-input {
      width: 400px;
      height: 34px;
      border: 1px solid rgba(238, 238, 238, 1);
      padding-inline: 12px;

      color: #555;
      font-size: 14px;

      &:hover {
        border-color: rgba(221, 221, 221, 1);
      }
    }

    &-error {
      margin-top: 5px;
      color: $red;
      font-size: 12px;
    }
  }

  &__name {
    margin-top: 10px;
    display: flex;
    gap: 3px;

    font-size: 13px;
    color: rgba(35,56,81,1);
  }

  &__line {
    margin-top: 20px;
    height: 1px;
    width: 100%;
    background-color: rgba(221, 221, 221, 1);
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    cursor: pointer;
    align-self: flex-end;
    background-color: $blue;
    display: flex;
    align-items: center;
    gap: 3px;
    transition: 0.2s;
    border-radius: 3px;
    padding: 12px 15px;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }
}

.required {

  &::after {
    content: "*";
    color: rgba(232, 40, 77, 1);
  }
}

.rotate {
  transform: rotate(0.5turn);
}

.red {
  background-color: $red;

  &:hover {
    background-color: $light-blue;
  }
}

@media (max-width: 600px) {
  .update {
    &__form {
      width: 100%;
    }

    &__block {
      flex-direction: column;
      gap: 5px;

      &-inner {
        width: 100%;
      }

      &-input {
        width: 100%;
      }
    }
  }
}
</style>
<template>
  <div class="page">
    <h1 class="page__title">Order History</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__inner">
          <p class="page__inner-title">Order details</p>
          <div class="page__inner-block">
            <p class="page__inner-text"><span>Order ID: </span> {{ orderId }}</p>
            <p class="page__inner-text"><span>Date Added: </span> {{ order?.buyTime ? new Date(order.buyTime).toLocaleDateString() : '-' }}</p>
            <p class="page__inner-text"><span>Shipping Method: </span> Flat Shipping Rate</p>
          </div>
        </div>
        <div class="page__addresses">
          <div class="page__block">
            <p class="page__inner-title">Payment address</p>
            <div class="page__block-inner">
              <p class="page__inner-text">{{ order.billingAddress?.street }}</p>
              <p class="page__inner-text">{{ order.billingAddress?.city.name }}</p>
              <p class="page__inner-text">{{ order.billingAddress?.region.name }}</p>
              <p class="page__inner-text">{{ order.billingAddress?.country.name }}</p>
            </div>
          </div>
          <div class="page__block">
            <p class="page__inner-title">Shipping address</p>
            <div class="page__block-inner">
              <p class="page__inner-text">{{ order.shippingAddress?.street }}</p>
              <p class="page__inner-text">{{ order.shippingAddress?.city.name }}</p>
              <p class="page__inner-text">{{ order.shippingAddress?.region.name }}</p>
              <p class="page__inner-text">{{ order.shippingAddress?.country.name }}</p>
            </div>
          </div>
        </div>
        <div class="page__table">
          <table class="page__table-inner">
            <thead>
              <tr>
                <th>PRODUCT NAME</th>
                <th>QUANTITY</th>
                <th>PRICE</th>
                <th>TOTAL</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="product in order.orderProducts?.edges"
                :key="product?.node.uuid"
              >
                <td>{{ product.node.product?.name }}</td>
                <td>{{ product.node.quantity }}</td>
                <td>{{ product.node.product?.price }}</td>
                <td>{{ product.node.buyPrice }}</td>
                <td>
                  <div class="page__buttons">
                    <router-link :to="({ name: 'product-page', params: { productId: product.node.product.uuid } })" class="page__buttons-button">
                      <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.78571 5H18.2251C19.5903 5 20.5542 6.33739 20.1225 7.63246L18.4558 12.6325C18.1836 13.4491 17.4193 14 16.5585 14H6.07142M4.78571 5L4.74531 4.71716C4.60455 3.73186 3.76071 3 2.76541 3H2M4.78571 5L6.07142 14M6.07142 14L6.25469 15.2828C6.39545 16.2681 7.23929 17 8.23459 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM11 19C11 20.1046 10.1046 21 9 21C7.89543 21 7 20.1046 7 19C7 17.8954 7.89543 17 9 17C10.1046 17 11 17.8954 11 19Z"
                            stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </router-link>
                    <router-link :to="({ name: 'return-form' })" class="page__buttons-button">
                      <svg height="24" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                        <g id="SVGRepo_iconCarrier">
                          <g id="Layer_2" data-name="Layer 2">
                            <g id="invisible_box" data-name="invisible box">
                                <rect width="48" height="48" fill="none"></rect>
                            </g>
                            <g id="Q3_icons" data-name="Q3 icons">
                                <path d="M19.7,6a.9.9,0,0,0-.8.4L2.4,23.1a1.5,1.5,0,0,0,0,2L18.9,41.6a.9.9,0,0,0,.8.4,1.2,1.2,0,0,0,1.1-1.3V31c15.7.7,21.1,3.8,23.5,9.2.4.8.8,1.1,1.1,1.1s.6-.4.6-1c-.2-10.5-10-20.9-25.2-22.4V7.3A1.2,1.2,0,0,0,19.7,6Z"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="page__info">
          <p class="page__info-text">Sub-Total: <span>{{ order.totalPrice }}€</span></p>
          <p class="page__info-text">Flat Shipping Rate: <span>5€</span></p>
          <p class="page__info-text">Total: <span>{{ order.totalPrice + 5 }}€</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Orders} from "@/core/api/order.js";
import {computed, onMounted, ref} from "vue";
import {useAuthStore} from "@/stores/auth.js";
import {useRoute} from "vue-router";

const route = useRoute()
const authStore = useAuthStore()

const accessToken = computed(() => {
  return authStore?.accessToken ? authStore?.accessToken : null
})
const userEmail = computed(() => {
  return authStore.user ? authStore.user.email : null
})
const orderId = computed(() => {
  return route.params.orderId
})

const order = ref([])

const getOrderById = async () => {
  const res = await Orders.getOrderById(
      accessToken.value,
    { userEmail: userEmail.value, uuid: orderId.value }
  );
  order.value = res.data.orders.edges[0].node
}

onMounted( async () => {
  await getOrderById()
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__info {
    background-color: #eeeeee;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-text {
      margin-left: 60%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-weight: 700;
      font-size: 16px;
      color: rgba(35,56,81,1);

      & span {
        font-weight: 400;
      }
    }
  }

  &__block {
    width: 50%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    &-inner {
      margin-top: 15px;
    }
  }

  &__addresses {
    margin-top: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__inner {
    margin-top: 25px;

    &-title {
      border-bottom: 2px solid rgba(238, 238, 238, 1);
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      color: rgba(35,56,81,1);
    }

    &-block {
      margin-top: 15px;
      display: grid;
      grid-template-columns: repeat(2, 50%);
      row-gap: 10px;
    }

    &-text {
      font-weight: 400;
      font-size: 14px;
      color: rgba(35,56,81,1);

      & span {
        font-weight: 700;
      }
    }
  }

  &__table {
    margin-top: 25px;
    width: 100%;
    overflow: auto;

    &-inner {
      width: 100%;
      border: 1px solid rgba(221,221,221,1);
      border-collapse: collapse;

      & tr {
        border-bottom: 1px solid rgba(221,221,221,1);
      }

      & th {
        padding: 8px 14px;

        font-size: 16px;
        font-weight: 700;
        color: rgba(35, 56, 81, 1);
      }

      & td {
        text-align: center;
        padding: 5px;

        font-size: 14px;
        color: rgba(35, 56, 81, 1);
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 5px;

    &-button {
      cursor: pointer;
      background-color: $blue;
      transition: 0.2s;
      width: 35px;
      aspect-ratio: 1;
      display: grid;
      place-items: center;

      &:last-child {
        background-color: $red;

        &:hover {
          background-color: $light-blue;
        }
      }

      &:hover {
        background-color: $orange;
      }
    }
  }
}

@media (max-width: 800px) {
  .page {
    &__inner {
      &-block {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 450px) {
  .page {
    &__addresses {
      flex-direction: column;
    }

    &__block {
      width: 100%;
    }
  }
}
</style>
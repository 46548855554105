import {apiUrl} from "@/core/api/index.js";

export class Locations {
  static async getCountries(name)  {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `{
          countries(name: "${name}") {
            edges {
              node {
                uuid
                name
              }
            }
          }
        }`
      })
    })

    return await response.json()
  }

  static async getCities(regionName, name)  {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `{
          cities(regionName: "${regionName}", orderBy: "name", name: "${name}") {
            edges {
              node {
                uuid
                name
              }
            }
          }
        }`
      })
    })

    return await response.json()
  }

  static async getRegions(countryName, name)  {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `{
          regions(countryName: "${countryName}" name: "${name}") {
            edges {
              node {
                uuid
                name
              }
            }
          }
        }`
      })
    })

    return await response.json()
  }

  static async getPostCodes(countryName, number)  {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `{
          postalCodes(countryName: "${countryName}", code: "${number}") {
            edges {
              node {
                uuid
                code
              }
            }
          }
        }`
      })
    })

    return await response.json()
  }
}
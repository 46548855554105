import {apiUrl} from "@/core/api/index.js";

export class Address {
  static async createAddress(street, postalCode, city, accessToken) {
    const token = localStorage.getItem('stz-refresh')
    if (!token) return false;

    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        query: `mutation {
          createAddress(street: "${street}", postalCodeUuid: "${postalCode}", cityUuid: "${city}") {
            address {
              uuid
              street
              country {
                uuid
                name
              }
              city {
                uuid
                name
              }
              postalCode {
                uuid
                name
              }
              region {
                uuid
                name
              }
            }
          }
        }`
      })
    })

    return await response.json()
  }

  static async deleteAddress(uuid, accessToken) {
    const token = localStorage.getItem('stz-refresh')
    if (!token) return false;

    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        query: `mutation {
          deleteAddress(uuid: "${uuid}") {
            success
          }
        }`
      })
    })

    return await response.json()
  }

  static async updateAddress(street, postalCode, city, uuid, accessToken) {
    const token = localStorage.getItem('stz-refresh')
    if (!token) return false;

    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        query: `mutation {
          updateAddress(street: "${street}", postalCodeUuid: "${postalCode}", cityUuid: "${city}", uuid: "${uuid}") {
            address {
              uuid
              street
              city {
                uuid
                name
              }
              country {
                uuid
                name
              }
              id
              postalCode {
                uuid
                code
              }
              region {
                uuid
                name
              }
            }
          }
        }`
      })
    })

    return await response.json()
  }

  static async getAddresses(accessToken) {
    const token = localStorage.getItem('stz-refresh')
    if (!token) return false;

    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        query: `{
          addresses {
            edges {
              node {
                uuid
                street
                postalCode {
                  uuid
                  code
                }
                city {
                  uuid
                  name
                }
                country {
                  uuid
                  name
                }
                region {
                  uuid
                  name
                }
              }
            }
          }
        }`
      })
    })

    return await response.json()
  }
}
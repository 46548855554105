import {apiUrl} from "@/core/api/index.js";

export class Orders {
  static async getPendingOrder(accessToken, query) {
    const payload = {
      query: `{
        orders(status: "PENDING", userEmail: ${JSON.stringify(query.userEmail)}) {
          edges {
            node {
              created
              uuid
              totalPrice
              orderProducts {
                edges {
                  node {
                    attributes
                    buyPrice
                    uuid
                    quantity
                    product {
                      uuid
                      price
                      name
                      attributes
                      images {
                        edges {
                          node {
                            image
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    };

    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    return await response.json();
  }

  static async getAllOrders(accessToken, query) {
    const payload = {
      query: `{
        orders(status: "FAILED, PAYMENT, CREATED, DELIVERING, FINISHED", orderBy: "buyTime", userEmail: ${JSON.stringify(query.userEmail)}) {
          edges {
            node {
              buyTime
              uuid
              status
              totalPrice
              totalQuantity
              orderProducts {
                edges {
                  node {
                    attributes
                    buyPrice
                    uuid
                    quantity
                    product {
                      uuid
                      price
                      name
                      attributes
                      images {
                        edges {
                          node {
                            image
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    };

    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    return await response.json();
  }

  static async getOrderById(accessToken, query) {
    const payload = {
      query: `{
        orders(uuid: "${query.uuid}", userEmail: ${JSON.stringify(query.userEmail)}) {
          edges {
            node {
              buyTime
              uuid
              status
              totalPrice
              orderProducts {
                edges {
                  node {
                    attributes
                    buyPrice
                    uuid
                    quantity
                    product {
                      uuid
                      price
                      name
                      attributes
                      images {
                        edges {
                          node {
                            image
                          }
                        }
                      }
                    }
                  }
                }
              }
              billingAddress {
                uuid
                street
                region {
                  name
                }
                postalCode {
                  code
                }
                country {
                  name
                }
                city {
                  name
                }
              }
              shippingAddress {
                uuid
                street
                region {
                  name
                }
                postalCode {
                  code
                }
                country {
                  name
                }
                city {
                  name
                }
              }
            }
          }
        }
      }`
    };

    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    return await response.json();
  }

  static async overwriteCart (action, accessToken, orderId, productId, color, size){
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        query: `mutation {
          ${action}(orderUuid: "${orderId}", productUuid: "${productId}", color: "${color}", size: "${size}") {
            order {
              uuid
              created
              totalPrice
              orderProducts {
                edges {
                  node {
                    attributes
                    buyPrice
                    quantity
                    product {
                      uuid
                      name
                      price
                      images {
                        edges {
                          node {
                            image
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`
      })
    })

    return response.json()
  }

  static async buyOrder (accessToken, billingId, shippingId, orderId){
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        query: `mutation {
          buyOrder(billingAddressUuid: "${billingId}", orderUuid: "${orderId}", shippingAddressUuid: "${shippingId}") {
            transaction {
              process
            }
            order {
              status
            }
          }
        }`
      })
    })

    return response.json()
  }
}
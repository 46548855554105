<template>
  <div class="page">
    <h1 class="page__title">Balance top up</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__block">
          <p class="page__name">Amount:</p>
          <input
            type="text"
            placeholder="Amount"
            class="page__block-input"
            v-model="amount"
          >
        </div>
        <button class="page__button" @click="topUp">TOP UP</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {apiUrl} from "@/core/api/index.js";
import {useAuthStore} from "@/stores/auth.js";
import {useAppStore} from "@/stores/app.js";

const authStore = useAuthStore()
const appStore = useAppStore()

const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})

const amount = ref(0)

const topUp = async () => {
  const response = await fetch(`${apiUrl}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken.value}`
    },
    body: JSON.stringify({
      query: `mutation {
        deposit(amount: ${amount.value}) {
          transaction {
            uuid
            process
          }
        }
      }`
    })
  })

  const res = await response.json()

  if (res.data.deposit.transaction.process) {
    window.location.href = res.data.deposit.transaction.process.redirect_url
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    width: fit-content;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    &-input {
      width: 320px;
      height: 34px;
      border: 1px solid rgba(238, 238, 238, 1);
      padding-inline: 12px;

      color: #555;
      font-size: 14px;

      &:hover {
        border-color: rgba(221, 221, 221, 1);
      }
    }
  }

  &__name {
    display: flex;
    gap: 3px;

    font-size: 18px;
    color: rgba(35,56,81,1);
  }

  &__button {
    width: 100%;
    cursor: pointer;
    background-color: $blue;
    border-radius: 2px;
    transition: 0.2s;
    padding-block: 10px;

    color: $white;
    font-size: 16px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }
  }
}

@media (max-width: 450px) {
  .page {
    &__block {
      &-input {
        width: 150px;
      }
    }
  }
}
</style>
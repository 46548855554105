import { createRouter, createWebHistory } from 'vue-router'
import HomePage from "@/pages/home-page.vue";
import ForgotPage from "@/pages/forgot-page.vue";
import ContactPage from "@/pages/contact-page.vue";
import WishlistPage from "@/pages/wishlist-page.vue";
import CartPage from "@/pages/cart-page.vue";
import StorePage from "@/pages/store-page.vue";
import BlogPage from "@/pages/blog-page.vue";
import ProductPage from "@/pages/product-page.vue";
import ProfilePage from "@/pages/profile-page.vue";
import AccountInformationPage from "@/pages/account-information-page.vue";
import {checkAuth} from "@/core/guards/notAuthGuard.js";
import BalancePage from "@/pages/balance-page.vue";
import PrivacyPage from "@/pages/privacy-page.vue";
import TermsPage from "@/pages/terms-page.vue";
import LicensePage from "@/pages/license-page.vue";
import DeliveryPage from "@/pages/delivery-page.vue";
import ReturnPolicyPage from "@/pages/return-policy-page.vue";
import ActivationPage from "@/pages/activation-page.vue";
import OrdersPage from "@/pages/orders-page.vue";
import ChangePasswordPage from "@/pages/change-password-page.vue";
import DownloadsPage from "@/pages/downloads-page.vue";
import ProductReturnPage from "@/pages/product-return-page.vue";
import TransactionsPage from "@/pages/transactions-page.vue";
import ReturnPage from "@/pages/return-page.vue";
import ResetPasswordPage from "@/pages/reset-password-page.vue";
import NewsletterPage from "@/pages/newsletter-page.vue";
import AddressbookPage from "@/pages/addressbook-page.vue";
import AddAddressPage from "@/pages/add-address-page.vue";
import EditAddressPage from "@/pages/edit-address-page.vue";
import CheckoutPage from "@/pages/checkout-page.vue";
import OrderDetailsPage from "@/pages/order-details-page.vue";

const routes = [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: ForgotPage
    },
    {
      path: '/contact',
      name: 'contact',
      component: ContactPage
    },
    {
      path: '/wishlist',
      name: 'wishlist',
      component: WishlistPage,
      beforeEnter: checkAuth
    },
    {
      path: '/cart',
      name: 'cart',
      component: CartPage,
      beforeEnter: checkAuth
    },
    {
      path: '/store/',
      name: 'store',
      component: StorePage
    },
    {
      path: '/blog',
      name: 'blog',
      component: BlogPage
    },
    {
      path: '/product/:productId',
      name: 'product-page',
      component: ProductPage
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfilePage,
      beforeEnter: checkAuth
    },
    {
      path: '/account-information',
      name: 'account-information',
      component: AccountInformationPage,
      beforeEnter: checkAuth
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: ChangePasswordPage,
      beforeEnter: checkAuth
    },
    {
      path: '/balance',
      name: 'balance',
      component: BalancePage,
      beforeEnter: checkAuth
    },
    {
      path: '/downloads',
      name: 'downloads',
      component: DownloadsPage,
      beforeEnter: checkAuth
    },
    {
      path: '/product-return',
      name: 'product-return',
      component: ProductReturnPage,
      beforeEnter: checkAuth
    },
    {
      path: '/return-form',
      name: 'return-form',
      component: ReturnPage,
      beforeEnter: checkAuth
    },
    {
      path: '/transaction',
      name: 'transaction',
      component: TransactionsPage,
      beforeEnter: checkAuth
    },
    {
      path: '/orders',
      name: 'orders',
      component: OrdersPage,
      beforeEnter: checkAuth
    },
    {
      path: '/orders/:orderId',
      name: 'order-details',
      component: OrderDetailsPage,
      beforeEnter: checkAuth
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: PrivacyPage
    },
    {
      path: '/terms',
      name: 'terms',
      component: TermsPage
    },
    {
      path: '/license',
      name: 'license',
      component: LicensePage
    },
    {
      path: '/delivery',
      name: 'delivery',
      component: DeliveryPage
    },
    {
      path: '/return',
      name: 'return',
      component: ReturnPolicyPage
    },
    {
      path: '/newsletter',
      name: 'newsletter',
      component: NewsletterPage,
      beforeEnter: checkAuth
    },
    {
      path: '/addressbook',
      name: 'addressbook',
      component: AddressbookPage,
      beforeEnter: checkAuth
    },
    {
      path: '/add-address',
      name: 'add-address',
      component: AddAddressPage,
      beforeEnter: checkAuth
    },
    {
      path: '/edit-address/:addressId',
      name: 'edit-address',
      component: EditAddressPage,
      beforeEnter: checkAuth
    },
    {
      path: '/activate-user',
      name: 'activation',
      component: ActivationPage
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPasswordPage
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: CheckoutPage
    }
  ]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    document.querySelector('#top').scrollIntoView({ behavior: 'smooth' })
    if (to.query !== from.query && to.name === from.name) {
      return savedPosition;
    } else {
      return {
        left: 0,
        top: 0 ,
        behavior: 'smooth'
      };
    }
  }
})

export default router

import { defineStore } from "pinia";
import {ref} from "vue";

export const useAppStore = defineStore('app', () => {
  const isProductDetailsVisible = ref(false)
  const setProductDetailsVisible = (state) => {
    isProductDetailsVisible.value = state
  }

  const activeProductId = ref(null)
  const setActiveProductId = (id) => {
    activeProductId.value = id
  }

  const isProductAttributesVisible = ref(false)
  const setProductAttributesVisible = (state) => {
    isProductAttributesVisible.value = state
  }

  const activeProductAttributes = ref(null)
  const setActiveProductAttributes = (product) => {
    activeProductAttributes.value = product
  }

  const isPopupVisible = ref(false)
    const setPopupVisible = (state) => {
        isPopupVisible.value = state
    }

  const popupArray = ref([])

  return {
    isProductDetailsVisible,
    setProductDetailsVisible,

    activeProductId,
    setActiveProductId,

    isProductAttributesVisible,
    setProductAttributesVisible,

    activeProductAttributes,
    setActiveProductAttributes,

    isPopupVisible,
    setPopupVisible,

    popupArray
  }
})
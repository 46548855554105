<template>
  <div class="return">
    <p class="return__text">Please complete the form below to request an RMA number.</p>
    <div class="return__form">
      <p class="return__title">Order Information</p>
      <div class="return__block">
        <div class="return__name required">First Name</div>
        <div class="return__block-inner">
          <input
              type="text"
              placeholder="First Name"
              class="return__block-input"
              v-model="firstName"
              @input="onFirstNameInput"
          >
          <p class="return__block-error" v-if="firstNameError">{{ firstNameError }}</p>
        </div>
      </div>
      <div class="return__block">
        <div class="return__name required">Last Name</div>
        <div class="return__block-inner">
          <input
              type="text"
              placeholder="Last Name"
              class="return__block-input"
              v-model="lastName"
              @input="onLastNameInput"
          >
          <p class="return__block-error" v-if="lastNameError">{{ lastNameError }}</p>
        </div>
      </div>
      <div class="return__block">
        <div class="return__name required">E-Mail</div>
        <div class="return__block-inner">
          <input
              type="email"
              placeholder="E-Mail"
              class="return__block-input"
              v-model="email"
              @input="onEmailInput"
          >
          <p class="return__block-error" v-if="emailError">{{ emailError }}</p>
        </div>
      </div>
      <div class="return__block">
        <div class="return__name required">Telephone</div>
        <div class="return__block-inner">
          <input
              type="text"
              placeholder="Telephone"
              class="return__block-input"
              v-model="phoneNumber"
              @input="onPhoneInput"
          >
          <p class="return__block-error" v-if="phoneNumberError">{{ phoneNumberError }}</p>
        </div>
      </div>
      <div class="return__block">
        <div class="return__name required">Order ID</div>
        <div class="return__block-inner">
          <input
              type="text"
              placeholder="Order ID"
              class="return__block-input"
              v-model="orderId"
              @input="onOrderIdInput"
          >
          <p class="return__block-error" v-if="orderIdError">{{ orderIdError }}</p>
        </div>
      </div>
      <p class="return__title">Product Information</p>
      <div class="return__block">
        <div class="return__name required">Product Name</div>
        <div class="return__block-inner">
          <input
              type="text"
              placeholder="Product Name"
              class="return__block-input"
              v-model="productName"
              @input="onProductNameInput"
          >
          <p class="return__block-error" v-if="productNameError">{{ productNameError }}</p>
        </div>
      </div>
      <div class="return__block">
        <div class="return__name required">Product Code</div>
        <div class="return__block-inner">
          <input
              type="text"
              placeholder="Product Code"
              class="return__block-input"
              v-model="productCode"
              @input="onProductCodeInput"
          >
          <p class="return__block-error" v-if="productCodeError">{{ productCodeError }}</p>
        </div>
      </div>
      <div class="return__block">
        <div class="return__name">Quantity</div>
        <div class="return__block-inner">
          <input
              type="email"
              placeholder="Quantity"
              class="return__block-input"
              v-model="quantity"
          >
        </div>
      </div>
      <div class="return__block-radios">
        <div class="return__name required">Reason for return</div>
        <div class="return__block-inner">
          <label class="return__block-label">
            <input
                type="radio"
                class="return__block-radio"
                name="reason"
                value="dead"
                v-model="reason"
            >
            Dead On Arrival
          </label>
          <label class="return__block-label">
            <input
                type="radio"
                class="return__block-radio"
                name="reason"
                value="faulty"
                v-model="reason"
            >
            Faulty, please supply details
          </label>
          <label class="return__block-label">
            <input
                type="radio"
                class="return__block-radio"
                name="reason"
                value="error"
                v-model="reason"
            >
            Order Error
          </label>
          <label class="return__block-label">
            <input
                type="radio"
                class="return__block-radio"
                name="reason"
                value="other"
                v-model="reason"
            >
            Other, please supply details
          </label>
          <label class="return__block-label">
            <input
                type="radio"
                class="return__block-radio"
                name="reason"
                value="wrong"
                v-model="reason"
            >
            Received Wrong Item
          </label>
        </div>
      </div>
      <div class="return__block-radios">
        <div class="return__name required">Product is opened</div>
        <div class="return__block-inner">
          <label class="return__block-label">
            <input
                type="radio"
                class="return__block-radio"
                name="isOpened"
                value="yes"
                v-model="isOpened"
            >
            Yes
          </label>
          <label class="return__block-label">
            <input
                type="radio"
                class="return__block-radio"
                name="isOpened"
                value="no"
                v-model="isOpened"
            >
            No
          </label>
        </div>
      </div>
      <div class="return__block">
        <div class="return__name">Faulty or other details</div>
        <div class="return__block-inner">
          <textarea
              type="email"
              placeholder="Faulty or other details"
              class="return__block-textarea"
              v-model="details"
          />
        </div>
      </div>
    </div>
    <div class="return__line"></div>
    <div class="return__buttons">
      <button @click="back" class="return__button red">
        <img class="rotate" src="@icons/arrowIcon.svg" alt="back">
        BACK
      </button>
      <button
          class="return__button"
          :disabled="!isFormValid"
      >
        CONTINUE
        <img src="@icons/arrowIcon.svg" alt="continue">
      </button>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {isEmail, required} from "@/core/rules/textFieldRules.js";
import {useRouter} from "vue-router";

const router = useRouter()

const firstName = ref('')
const lastName = ref('')
const email = ref('')
const phoneNumber = ref('')
const orderId = ref('')
const productName = ref('')
const productCode = ref('')
const quantity = ref('')
const reason = ref('')
const isOpened = ref('')
const details = ref('')

const firstNameError = ref('')
const lastNameError = ref('')
const emailError = ref('')
const phoneNumberError = ref('')
const orderIdError = ref('')
const productNameError = ref('')
const productCodeError = ref('')

const onFirstNameInput = (e) => {
  if (required(e.target.value) !== true) {
    firstNameError.value = required(e.target.value)
  } else {
    firstNameError.value = ""
  }
}
const onLastNameInput = (e) => {
  if (required(e.target.value) !== true) {
    lastNameError.value = required(e.target.value)
  } else {
    lastNameError.value = ""
  }
}
const onEmailInput = (e) => {
  if (isEmail(e.target.value) !== true) {
    emailError.value = isEmail(e.target.value)
  } else {
    emailError.value = ""
  }
}
const onPhoneInput = (e) => {
  if (required(e.target.value) !== true) {
    phoneNumberError.value = required(e.target.value)
  } else {
    phoneNumberError.value = ""
  }
}
const onOrderIdInput = (e) => {
  if (required(e.target.value) !== true) {
    orderIdError.value = required(e.target.value)
  } else {
    orderIdError.value = ""
  }
}
const onProductNameInput = (e) => {
  if (required(e.target.value) !== true) {
    productNameError.value = required(e.target.value)
  } else {
    productNameError.value = ""
  }
}
const onProductCodeInput = (e) => {
  if (required(e.target.value) !== true) {
    productCodeError.value = required(e.target.value)
  } else {
    productCodeError.value = ""
  }
}

const isFormValid = computed(() => {
  return (
    required(firstName.value) &&
    required(lastName.value) &&
    required(phoneNumber.value) &&
    isEmail(email.value) === true
  )
})

const back = () => {
  router.go(-1)
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.return {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;

  &__title {
    text-transform: uppercase;
    color: $dark-blue;
    font-weight: 700;
    font-size: 16px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(35,56,81,1);
  }

  &__form {
    margin-top: 20px;
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  &__block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-inner {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &-radios {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 40px;
    }

    &-label {
      cursor: pointer;
      color: rgba(35,56,81,1);
      font-size: 13px;
    }

    &-input {
      width: 400px;
      height: 34px;
      border: 1px solid rgba(238, 238, 238, 1);
      padding-inline: 12px;

      color: #555;
      font-size: 14px;

      &:hover {
        border-color: rgba(221, 221, 221, 1);
      }
    }

    &-textarea {
      width: 400px;
      height: 300px;
      border: 1px solid rgba(238, 238, 238, 1);
      padding: 12px;

      color: #555;
      font-size: 14px;
    }

    &-error {
      margin-top: 5px;
      color: $red;
      font-size: 12px;
    }
  }

  &__name {
    margin-top: 10px;
    display: flex;
    gap: 3px;

    font-size: 13px;
    color: rgba(35,56,81,1);
  }

  &__line {
    margin-top: 20px;
    height: 1px;
    width: 100%;
    background-color: rgba(221, 221, 221, 1);
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    cursor: pointer;
    align-self: flex-end;
    background-color: $blue;
    display: flex;
    align-items: center;
    gap: 3px;
    transition: 0.2s;
    border-radius: 3px;
    padding: 12px 15px;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }
}

.required {

  &::after {
    content: "*";
    color: rgba(232, 40, 77, 1);
  }
}

.rotate {
  transform: rotate(0.5turn);
}

.red {
  background-color: $red;

  &:hover {
    background-color: $light-blue;
  }
}

@media (max-width: 600px) {
  .return {
    &__form {
      width: 100%;
    }

    &__block {
      flex-direction: column;
      gap: 5px;

      &-inner {
        width: 100%;
      }

      &-input {
        width: 100%;
      }
    }
  }

  .el-select {
    width: 100% !important;
  }
}
</style>
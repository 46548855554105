<template>
  <div class="page">
    <h1 class="page__title">Newsletter Subscription</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__form">
          <div class="page__block">
            <div class="page__name required">Subscribe</div>
            <div class="page__block-inner">
              <div class="page__radios">
                <label class="page__label">
                  <input
                      type="radio"
                      name="subscribe"
                      value="true"
                      class="page__block-radio"
                      v-model="isSubscibed"
                  >
                  Yes
                </label>
                <label class="page__label">
                  <input
                      type="radio"
                      name="subscribe"
                      value="false"
                      class="page__block-radio"
                      v-model="isSubscibed"
                  >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="page__line"></div>
        <div class="page__buttons">
          <button @click="back" class="page__button red">
            <img class="rotate" src="@icons/arrowIcon.svg" alt="back">
            BACK
          </button>
          <button
              class="page__button"
              @click="subscribe"
          >
            CONTINUE
            <img src="@icons/arrowIcon.svg" alt="continue">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {useAuthStore} from "@/stores/auth.js";
import {useRouter} from "vue-router";
import {useAppStore} from "@/stores/app.js";

const router = useRouter()
const authStore = useAuthStore()
const appStore = useAppStore()

const isUserSubscribed = computed(() => {
  return authStore.user ? authStore.user.isSubscribed : false  
})

const isSubscibed = ref(isUserSubscribed.value)

const back = () => {
  router.go(-1)
}

const subscribe = () => {
  const popup = {
    text: 'You have successfully subscribed to our newsletter!',
    tag: 'success',
    id: Math.random() * 2000
  }
  appStore.popupArray.unshift(popup)
  appStore.setPopupVisible(true)
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    padding-block: 20px;
  }

  &__form {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 25px;
  }

  &__radios {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__name {
    margin-top: 2px;
    display: flex;
    gap: 3px;

    font-size: 13px;
    color: rgba(35,56,81,1);
  }

  &__label {
    cursor: pointer;
    font-size: 13px;
  }

  &__line {
    margin-block: 20px;
    height: 1px;
    width: 100%;
    background-color: rgba(221, 221, 221, 1);
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    cursor: pointer;
    align-self: flex-end;
    background-color: $blue;
    display: flex;
    align-items: center;
    gap: 3px;
    transition: 0.2s;
    border-radius: 3px;
    padding: 12px 15px;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }
}

.rotate {
  transform: rotate(0.5turn);
}

.red {
  background-color: $red;

  &:hover {
    background-color: $light-blue;
  }
}
</style>
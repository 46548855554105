import {apiUrl} from "@/core/api/index.js";

export class Auth {
  static async Register(payload) {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    return await response.json();
  }

  static async Login(payload) {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
    return await response.json();
  }

  static async Authenticate() {
    const token = localStorage.getItem('stz-refresh')
    if (!token) return false;

    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `mutation {
          refreshJwtToken(refreshToken: "${token}") {
            refreshToken
            accessToken
            user {
              uuid
              firstName
              lastName
              email
              phoneNumber
              isSubscribed
              balance {
                amount
              }
            }
          }
        }`
      })
    })
    return await response.json()
  }

  static async UpdateData(accessToken, payload){
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload)
    })
    return await response.json();
  }

  static async Activate(token, uuid) {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `mutation {
          activateUser(token: "${token}", uid: "${uuid}") {
            success
          }
        }`
      })
    })
    return await response.json();
  }

  static async ResetPassword(email){
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `mutation {
          resetPassword(email: "${email}") {
            success
          }
        }`
      })
    })
    return await response.json();
  }

  static async confirmResetPassword(token, uuid, password, passwordConfirm) {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `mutation {
          confirmResetPassword(token: "${token}", uid: "${uuid}", password: "${password}", confirmPassword: "${passwordConfirm}") {
            success
          }
        }`
      })
    })
    return await response.json();
  }
}


<template>
  <div class="modal">
    <div class="modal__bg" @click="closeModal"></div>
    <div class="modal__content">
      <div class="modal__close" @click="closeModal">
        <img src="@icons/crossIcon.svg" alt="close">
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { useAuthModalsStore } from '@/stores/authModals.js';
import {watch} from "vue";
import {useRoute} from "vue-router";

const route = useRoute()
const authModalsStore = useAuthModalsStore()

const closeModal = () => {
  authModalsStore.setSignUp(false)
  authModalsStore.setSignIn(false)
}

watch(route, () => {
  authModalsStore.setSignUp(false)
  authModalsStore.setSignIn(false)
});
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.modal {
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__bg {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    position: absolute;
    z-index: 2;
  }

  &__content {
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
    background-color: $white;
    border-radius: 3px;
    padding: 20px;
    width: 500px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 4;
    background-color: $orange;
    border-radius: 50%;
    display: grid;
    place-items: center;
    padding: 5px;
    transition: 0.2s;

    &:hover {
      background-color: $blue;
    }
  }
}

@media (max-width: 500px) {
  .modal {
    &__content {
      width: 300px;
    }
  }
}
</style>
import {defineStore} from "pinia";
import {ref} from "vue";

export const useAddressStore = defineStore('address', () => {
  const addresses = ref([])
  const setAddress = (payload) => {
    addresses.value = payload
  }

  return { addresses, setAddress }
})
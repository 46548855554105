<template>
  <div class="page">
    <h1 class="page__title">Address Book Entries</h1>
    <div class="container">
      <div class="page__wrapper">
        <div
          class="page__address"
          v-for="address in addresses"
          :key="address.node.uuid"
          v-if="addresses.length"
        >
          <div class="page__address-info">
            <p class="page__address-text">{{ user.firstName }} {{ user.lastName }}</p>
            <p class="page__address-text">{{ address.node.street }}</p>
            <p class="page__address-text">{{ address.node.postalCode.code }} {{ address.node.city.name }}</p>
            <p class="page__address-text">{{ address.node.country.name }}</p>
          </div>
          <div class="page__address-actions">
            <router-link :to="({ name: 'edit-address', params: { addressId: address.node.uuid } })" class="page__address-button">EDIT</router-link>
            <button class="page__address-button" @click="deleteAddress(address.node.uuid)">DELETE</button>
          </div>
        </div>
        <p class="page__text" v-else>You have no addresses in your account.</p>
        <div class="page__line"></div>
        <div class="page__buttons">
          <button @click="back" class="page__button red">
            <img class="rotate" src="@icons/arrowIcon.svg" alt="back">
            BACK
          </button>
          <button
            class="page__button"
            @click="addAddress"
          >
            NEW ADDRESS
            <img src="@icons/arrowIcon.svg" alt="continue">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import {useAddressStore} from "@/stores/address.js";
import {computed} from "vue";
import {useAuthStore} from "@/stores/auth.js";
import {Address} from "@/core/api/address.js";
import {useAppStore} from "@/stores/app.js";

const router = useRouter()
const addressStore = useAddressStore()
const authStore = useAuthStore()
const appStore = useAppStore()

const addresses = computed(() => {
  return addressStore.addresses ? addressStore.addresses : null
})
const user = computed(() => {
  return authStore.user ? authStore.user : null
})
const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})

const back = () => {
  router.go(-1)
}

const deleteAddress = async (uuid) => {
  const res = await Address.deleteAddress(uuid, accessToken.value)

  if (res.data.deleteAddress.success) {
    const addressResult = await Address.getAddresses(accessToken.value)
    addressStore.setAddress(addressResult.data.addresses.edges)

    const popup = {
      text: 'You have successfully deleted an address!',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}

const addAddress = () => {
  router.push({
    name: 'add-address'
  })
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-top: 20px;
  }

  &__address {
    border: 1px solid rgba(221,221,221,1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 14px;
    margin-bottom: 25px;

    &-text {
      font-weight: 400;
      font-size: 16px;
      color: rgba(35,56,81,1);
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &-button {
      cursor: pointer;
      padding-inline: 15px;
      height: 36px;
      background-color: rgba(109,118,125,1);
      display: grid;
      place-items: center;
      transition: 0.2s;

      font-size: 12px;
      color: rgba(255,255,255,1);
      font-weight: 700;

      &:last-child {
        background-color: $red;
      }

      &:hover {
        background-color: $light-blue;
      }
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(35,56,81,1);
  }

  &__line {
    margin-bottom: 25px;
    width: 100%;
    height: 1px;
    background-color: rgba(221, 221, 221, 1);
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    cursor: pointer;
    align-self: flex-end;
    background-color: $blue;
    display: flex;
    align-items: center;
    gap: 3px;
    transition: 0.2s;
    border-radius: 3px;
    padding: 12px 15px;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }
}

.rotate {
  transform: rotate(0.5turn);
}

.red {
  background-color: $red;

  &:hover {
    background-color: $light-blue;
  }
}

@media (max-width: 450px) {
  .page {
    &__address {
      flex-direction: column;
      gap: 15px;

      &-text {
        text-align: center;
      }
    }
  }
}
</style>
<template>
  <div class="register">
    <h2 class="register__title">YOUR PERSONAL DETAILS</h2>
    <div class="register__block">
      <div class="register__name required">First Name</div>
      <div class="register__block-inner">
        <input
            type="text"
            placeholder="First Name"
            class="register__block-input"
            v-model="firstName"
            @input="onFirstNameInput"
        >
        <p class="register__block-error" v-if="firstNameError">{{ firstNameError }}</p>
      </div>
    </div>
    <div class="register__block">
      <div class="register__name required">Last Name</div>
      <div class="register__block-inner">
        <input
            type="text"
            placeholder="Last Name"
            class="register__block-input"
            v-model="lastName"
            @input="onLastNameInput"
        >
        <p class="register__block-error" v-if="lastNameError">{{ lastNameError }}</p>
      </div>
    </div>
    <div class="register__block">
      <div class="register__name required">E-Mail</div>
      <div class="register__block-inner">
        <input
            type="email"
            placeholder="E-Mail"
            class="register__block-input"
            v-model="email"
            @input="onEmailInput"
        >
        <p class="register__block-error" v-if="emailError">{{ emailError }}</p>
      </div>
    </div>
    <div class="register__block">
      <div class="register__name required">Telephone</div>
      <div class="register__block-inner">
        <input
            type="text"
            placeholder="Telephone"
            class="register__block-input"
            v-model="phoneNumber"
            @input="onPhoneInput"
        >
        <p class="register__block-error" v-if="phoneNumberError">{{ phoneNumberError }}</p>
      </div>
    </div>
    <h2 class="register__title">YOUR PASSWORD</h2>
    <div class="register__block">
      <div class="register__name required">Password</div>
      <div class="register__block-inner">
        <input
            type="password"
            placeholder="Password"
            class="register__block-input"
            v-model="password"
            @input="onPasswordInput"
        >
        <p class="register__block-error" v-if="passwordError">{{ passwordError }}</p>
      </div>
    </div>
    <div class="register__block">
      <div class="register__name required">Password Confirm</div>
      <div class="register__block-inner">
        <input
            type="password"
            placeholder="Password Confirm"
            class="register__block-input"
            v-model="passwordConfirm"
            @input="onPasswordConfirmInput"
        >
        <p class="register__block-error" v-if="passwordConfirmError">{{ passwordConfirmError }}</p>
      </div>
    </div>
    <h2 class="register__title">NEWSLETTER</h2>
    <div class="register__sub">
      <div class="register__name">Subscribe</div>
      <div class="register__radios">
        <label class="register__label">
          <input
              type="radio"
              name="subscribe"
              value="Yes"
              class="register__block-radio"
              v-model="isSubscribedRadio"
          >
          Yes
        </label>
        <label class="register__label">
          <input
              type="radio"
              name="subscribe"
              value="No"
              class="register__block-radio"
              v-model="isSubscribedRadio"
          >
          No
        </label>
      </div>
    </div>
    <div class="register__line"></div>
    <div class="register__bottom">
      <label class="register__label">
        <input
            type="checkbox"
            v-model="isConfirm"
        >
        I have read and agree to the Privacy Policy
      </label>
      <button
          class="register__button"
          :disabled="!isFormValid"
          @click="register"
      >
        Continue
        <loader v-if="isLoading" />
        <img v-else src="@icons/arrowIcon.svg" alt="register">
      </button>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {isEmail, isPasswordValid, required} from "@/core/rules/textFieldRules.js";
import {Auth} from "@/core/api/auth.js";
import Loader from "@/components/UI/loader.vue";
import {useAuthModalsStore} from "@/stores/authModals.js";
import {useAppStore} from "@/stores/app.js";

const appStore = useAppStore()
const authModalsStore = useAuthModalsStore()

const firstName = ref('')
const lastName = ref('')
const email = ref('')
const phoneNumber = ref('')
const password = ref('')
const passwordConfirm = ref('')
const isConfirm = ref(false)
const isSubscribedRadio = ref('Yes')

const isLoading = ref(false)

const isSubscribed = computed(() => {
  return isSubscribedRadio.value === 'Yes'
})

const firstNameError = ref('')
const lastNameError = ref('')
const emailError = ref('')
const phoneNumberError = ref('')
const passwordError = ref('')
const passwordConfirmError = ref('')

const onPhoneInput = (e) => {
  if (required(e.target.value) !== true) {
    phoneNumberError.value = required(e.target.value)
  } else {
    phoneNumberError.value = ""
  }
}
const onFirstNameInput = (e) => {
  if (required(e.target.value) !== true) {
    firstNameError.value = required(e.target.value)
  } else {
    firstNameError.value = ""
  }
}
const onLastNameInput = (e) => {
  if (required(e.target.value) !== true) {
    lastNameError.value = required(e.target.value)
  } else {
    lastNameError.value = ""
  }
}
const onEmailInput = (e) => {
  if (isEmail(e.target.value) !== true) {
    emailError.value = isEmail(e.target.value)
  } else {
    emailError.value = ""
  }
}
const onPasswordInput = (e) => {
  if (isPasswordValid(e.target.value) !== true) {
    passwordError.value = isPasswordValid(e.target.value)
  } else {
    passwordError.value = ""
  }
}
const onPasswordConfirmInput = (e) => {
  if (password.value !== passwordConfirm.value) {
    passwordConfirmError.value = "Passwords don't match!"
  } else {
    passwordConfirmError.value = ""
  }
}

const isFormValid = computed(() => {
  return (
    required(firstName.value) &&
    required(lastName.value) &&
    required(phoneNumber.value) &&
    isEmail(email.value) === true &&
    isPasswordValid(password.value) === true &&
    password.value === passwordConfirm.value &&
    isConfirm.value
  )
})

const register = async () => {
  isLoading.value = true

  const result = await Auth.Register({
    query: `mutation {
      createUser(
        firstName: "${firstName.value}"
        lastName: "${lastName.value}"
        email: "${email.value}"
        phoneNumber: "${phoneNumber.value}"
        password: "${password.value}"
        confirmPassword: "${passwordConfirm.value}"
        isSubscribed: ${isSubscribed.value}
      ) {
        user {
          uuid
        }
      }
    }`
  })

  if (result.data.createUser.user) {
    const popup = {
      text: 'You have successfully created an account',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)

    authModalsStore.setSignUp(false)
  } else {
    const popup = {
      text: 'Something went wrong',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }

  isLoading.value = false
};
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.register {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;

  &__title {
    color: rgba(35,56,81,1);
    font-weight: 700;
    font-size: 15px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-input {
      width: 320px;
      height: 34px;
      border: 1px solid rgba(238, 238, 238, 1);
      padding-inline: 12px;

      color: #555;
      font-size: 14px;

      &:hover {
        border-color: rgba(221, 221, 221, 1);
      }
    }

    &-error {
      margin-top: 5px;
      color: $red;
      font-size: 12px;
    }

    &-radio {
      cursor: pointer;
    }
  }

  &__name {
    margin-top: 10px;
    display: flex;
    gap: 3px;

    font-size: 13px;
    color: rgba(35,56,81,1);
  }

  &__sub {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__radios {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__label {
    cursor: pointer;
    font-size: 13px;
  }

  &__forgot {
    transition: 0.2s;
    color: #337ab7;
    font-size: 16px;

    &:hover {
      color: $orange;
    }
  }

  &__line {
    margin-top: 20px;
    height: 1px;
    width: 100%;
    background-color: rgba(221, 221, 221, 1);
  }

  &__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    cursor: pointer;
    align-self: flex-end;
    background-color: $blue;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: 0.2s;
    border-radius: 3px;
    padding: 12px 15px;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }
}

.required {

  &::after {
    content: "*";
    color: rgba(232, 40, 77, 1);
  }
}

@media (max-width: 500px) {
  .register {
    &__block {
      flex-direction: column;
      gap: 5px;

      &-input {
        width: 260px;
      }
    }

    &__sub {
      width: 70%;
    }
  }
}
</style>
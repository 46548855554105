<template>
  <div class="carousel">
    <div class="carousel__nav">
      <p
        class="carousel__nav-item"
        :class="{active: category.node.name === activeCategory}"
        @click="toggleItem(category.node.name)"
        v-for="category in categories"
        :key="category.uuid"
      >
        {{ category.node.name }}
      </p>
    </div>
    <Swiper
      :modules="[Autoplay]"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      :slides-per-view="5"
      :loop="true"
      :speed="1000"
      class="swiper"
      :breakpoints="{
        200: {
          spaceBetween: 20,
          slidesPerView: 1
        },
        600: {
          spaceBetween: 20,
          slidesPerView: 2
        },
        650: {
          spaceBetween: 50,
          slidesPerView: 2
        },
        800: {
          spaceBetween: 10,
          slidesPerView: 3
        },
        1100: {
          spaceBetween: 20,
          slidesPerView: 4
        },
        1450: {
          spaceBetween: 30,
          slidesPerView: 5
        }
      }"
    >
      <Swiper-slide v-for="product in products" :key="product.node.uuid">
        <product-card
          v-if="products.length > 0"
          :product="product.node"
          :width="250"
        />
        <product-card-skeleton
          v-for="idx in 5"
          v-else
        />
      </Swiper-slide>
    </Swiper>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import {Autoplay} from "swiper/modules";
import ProductCard from "@/components/product/product-card.vue";
import {onMounted, ref, watch} from "vue";
import {Categories} from "@/core/api/categories.js";
import {Products} from "@/core/api/products.js";
import ProductCardSkeleton from "@/components/skeletons/product-card-skeleton.vue";

const categories = ref([])
const products = ref([])

const activeCategory = ref('')

const toggleItem = (itemName) => {
  activeCategory.value = itemName
}

const getCategories = async () => {
  const res = await Categories.getAllCategories()
  categories.value = res.data.categories.edges
}
const getProducts = async () => {
  products.value = []
  const res = await Products.getAllProducts(0, '0', '100', '', activeCategory.value,'', 15, '', '')
  products.value = res.data.products.edges
}

watch(
  () => activeCategory.value,
  async () => {
    await getProducts()
  },
  { deep: true }
)

onMounted(async () => {
  await getCategories()
  activeCategory.value = categories.value[0]?.node.name
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.carousel {
  padding-top: 50px;
  padding-inline: 100px;
  background-color: $white;

  &__nav {
    display: flex;
    align-items: center;

    &-item {
      position: relative;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      padding: 0 10px 8px 10px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 0;
        transition: all .2s ease;
        background-color: rgba(143, 210, 238, 1);
      }
      &.active:after {
        width: 100%;
      }
    }
  }
}

.swiper {
  margin-top: 25px;
}

@media (max-width: 1300px) {
  .carousel {
    padding-inline: 50px;
  }
}

@media (max-width: 600px) {
  .swiper {
    width: 250px;
    margin-inline: auto;
  }
}
</style>
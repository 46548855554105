<template>
  <div class="page">
    <h1 class="page__title">User Ativation</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__block" v-if="isActivated">
          <p class="page__text">Your account was successfully activated!</p>
          <button @click="goLogin" class="page__button">Go to Login</button>
        </div>
        <p class="page__text" v-else>Something went wrong!</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Auth} from "@/core/api/auth.js";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {useAppStore} from "@/stores/app.js";

const appStore = useAppStore()
const route = useRoute()
const router = useRouter()

const uid = computed(() =>
  route.query['uid'] ? (route.query['uid']) : undefined
)
const token = computed(() =>
  route.query['token'] ? (route.query['token']) : undefined
)

const isActivated = ref(false)

const activateUser = async () => {
  const res = await Auth.Activate(token.value, uid.value)
  isActivated.value = !!res.data.activateUser.success;
}

const goLogin = () => {
  router.push({
    name: 'home'
  })
  setTimeout(() => {
    appStore.setSignIn(true)
  }, 1000)
}

onMounted(async () => {
    await activateUser()
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
    color: rgba(35,56,81,1);
  }

  &__button {
    cursor: pointer;
    background-color: $blue;
    border-radius: 2px;
    padding: 8px 15px;
    transition: 0.2s;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }
  }
}
</style>
<template>
  <div class="page">
    <h1 class="page__title">Return and refund policy</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__block">
          <p class="page__subtitle">RETURN ELIGIBILITY</p>
          <p class="page__text">Returns are accepted within 14 days of receiving your order. Items must be unused, in their original condition, and include all tags and packaging. Personalized or customized products are generally not eligible for return, except if they arrive damaged or defective.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">RETURN PROCEDURE</p>
          <p class="page__text">To initiate a return, contact our customer support team within 14 days using the <router-link class="page__link" to="/return-form">return form</router-link> provided. Once your request is received, a representative will reach out to confirm the return details. Be sure to securely package the items to prevent any damage during return shipping.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">RETURN SHIPPING</p>
          <p class="page__text">You are responsible for return shipping costs unless the return is due to an error on our part or involves a damaged/defective item. It’s recommended to use a trackable shipping method to ensure safe and timely delivery of the returned items.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">REFUND POLICY</p>
          <p class="page__text">Refunds are processed after the returned items are inspected to ensure they meet our return criteria. Refunds will be issued to the original payment method, but original shipping fees (if applicable) are non-refundable unless the return is due to an error or a damaged/defective item. It may take up to 3 business days for the refund to appear in your account, depending on your bank or credit card provider.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">EXCHANGES</p>
          <p class="page__text">If you wish to exchange an item for a different size, color, or model, please contact our customer support at <a class="page__link" :href="'mailto:' + companyEmail">{{ companyEmail }}</a> to check availability and explore exchange options.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useCompanyStore} from "@/stores/company.js";
import {computed} from "vue";

const companyStore = useCompanyStore()

const companyEmail = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo.companyEmail : ''
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-block: 50px;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__subtitle {
    font-size: 16px;
    color: rgba(35,56,81,1);
    font-weight: 700;
  }

  &__ul {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(35,56,81,1);
  }

  &__link {
    font-weight: 700;
  }
}
</style>
<template>
  <div class="page">
    <h1 class="page__title">Terms & Use</h1>
    <div class="container">
      <div class="page__wrapper">
        <p class="page__text">Please carefully review these Terms of Use before accessing or using this website ("Site").</p>
        <div class="page__block">
          <p class="page__text"><span>Acceptance of Terms </span>By accessing or using this Site, you agree to be bound by the terms outlined here, as well as any updated terms that {{ companyName }} may post periodically (collectively referred to as "Terms of Use"). If you do not agree to these terms, do not use the Site. {{ companyName }} may revise these Terms of Use at any time, and your continued use of the Site indicates your acceptance of such changes. You are responsible for checking the Terms of Use link on {{ companyName }}’s homepage for the latest version. If you violate any of these terms, your right to use the Site will automatically terminate.</p>
        </div>
        <div class="page__block">
          <p class="page__text"><span>Intellectual Property and User License </span>As long as you comply with these Terms of Use, relevant license agreements, and applicable laws, you may access and use the Site. However, {{ companyName }} retains full ownership of the Site and reserves all rights not explicitly granted. {{ companyName }} may modify, replace, or discontinue any part of the Site at its discretion. The Site and its content are provided "as is" without warranties.</p>
          <p class="page__text">All content on the Site, including images and metadata (collectively "{{ companyName }} Content"), is protected by various intellectual property laws. Any unauthorized use violates these laws. Unless explicitly permitted under a separate agreement with {{ companyName }}, you may not use, copy, modify, or distribute any {{ companyName }} Content. You also agree not to use data mining or similar methods on the Site. Unauthorized downloading, displaying, or copying of content is prohibited unless a license has been obtained.</p>
        </div>
        <div class="page__block">
          <p class="page__text"><span>Trademarks </span>{{ companyName }} trademarks, including logos, trade names, and service marks, are protected. You do not have any rights to use these trademarks unless expressly permitted. Misuse or any attempt to challenge these trademarks is prohibited. You may not use {{ companyName }} trademarks, logos, or other proprietary content in domain names or as part of programming codes without written consent.</p>
        </div>
        <div class="page__block">
          <p class="page__text"><span>User-Provided Information </span>{{ companyName }} will handle your personal information according to its Privacy Policy, which is incorporated into these Terms of Use.</p>
        </div>
        <div class="page__block">
          <p class="page__text"><span>Your Content </span>When uploading content to the Site, you confirm that:</p>
          <ol class="page__ul">
            <li class="page__text">You have the rights to the content.</li>
            <li class="page__text">{{ companyName }} won’t need additional licenses or pay royalties to third parties.</li>
            <li class="page__text">Your content does not violate any third-party rights or applicable laws.</li>
          </ol>
          <p class="page__text">By uploading content, you grant {{ companyName }} a non-exclusive, royalty-free, worldwide license to use, display, and modify the content for the Site's operations. This license continues until you remove the content from the Site, except for legal archival purposes.</p>
          <p class="page__text">You may not upload content that infringes on others' rights, promotes unlawful acts, exploits minors, or violates any laws.</p>
        </div>
        <div class="page__block">
          <p class="page__text"><span>Infringement Claims </span>If you believe any content on the Site infringes on your copyright, you can notify {{ companyName }} at <a class="page__link" :href="'mailto:' + companyEmail">{{ companyEmail }}</a>.</p>
        </div>
        <div class="page__block">
          <p class="page__text"><span>Usage Restrictions </span>You must not engage in any unlawful activities, violate these Terms of Use, or infringe on {{ companyName }}'s rights or those of third parties. {{ companyName }} may terminate your access to the Site at any time if you violate these terms.</p>
        </div>
        <div class="page__block">
          <p class="page__text"><span>Third-Party Links </span>{{ companyName }} is not responsible for any third-party platforms that may link to or from the Site. Such links do not imply endorsement by {{ companyName }}.</p>
        </div>
        <div class="page__block">
          <p class="page__text"><span>Warranties and Disclaimers </span>Your use of the Site is at your own risk. The Site is provided "as is," without warranties of any kind. {{ companyName }} disclaims all implied warranties, including merchantability and fitness for a particular purpose. {{ companyName }} does not guarantee uninterrupted access to the Site or that it will meet your expectations.</p>
        </div>
        <div class="page__block">
          <p class="page__text"><span>Indemnification </span>You agree to indemnify {{ companyName }} and its affiliates against any claims or damages arising from your use of the Site or breach of these Terms of Use.</p>
        </div>
        <div class="page__block">
          <p class="page__text"><span>Miscellaneous </span>Any disputes will be resolved through arbitration in the United Kingdom, and the Terms of Use are governed by UK law. In the event of a conflict between these terms and any specific license agreements, the license agreement will take precedence. If any part of these Terms of Use is found invalid, the remaining terms will remain enforceable.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useCompanyStore} from "@/stores/company.js";
import {computed} from "vue";

const companyStore = useCompanyStore()

const companyName = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo.companyName : ''
})
const companyEmail = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo.companyEmail : ''
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-block: 50px;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__ul {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(35,56,81,1);

    span {
      font-weight: 700;
    }
  }

  &__link {
    font-weight: 700;
  }
}
</style>
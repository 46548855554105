import {apiUrl} from "@/core/api/index.js";

export class Wishlist {
  static async getWishlist(uuid, accessToken) {
    const payload = {
      query: `{
        users(uuid: "${uuid}") {
          edges {
            node {
              wishlist {
                uuid
                products {
                  edges {
                    node {
                      uuid
                      name
                      price
                      stocks {
                        edges {
                          node {
                            quantity
                          }
                        }
                      }
                      images {
                        edges {
                          node {
                            image
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    };

    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })

    return await response.json()
  }

  static async overwriteWishlist(action, productId, wishlistId, accessToken)  {
    const response = await fetch(`${apiUrl}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `mutation {
          ${action}(productUuid: "${productId}", wishlistUuid: "${wishlistId}") {
            wishlist {
              uuid
              products {
                edges {
                  node {
                    uuid
                    name
                    price
                    stocks {
                      edges {
                        node {
                          quantity
                        }
                      }
                    }
                    images {
                      edges {
                        node {
                          image
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`
      })
    })

    return await response.json()
  }
}
<template>
  <div class="categories">
    <div class="container">
      <div class="categories__wrapper">
        <p class="categories__title">TOP CATEGORIES</p>
        <div class="categories__items">
          <router-link
            :to="({ name: 'store', query: { categories: category.node.name } })"
            class="categories__item"
            v-for="category in categories"
            :key="category.node.uuid"
          >
            <img :src="category.node.image" alt="category">
            <button class="categories__button">{{ category.node.name }}</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {Categories} from "@/core/api/categories.js";

const categories = ref([])

const getCategories = async () => {
  const res = await Categories.getAllCategories()
  categories.value = res.data.categories.edges
}

onMounted(async () => {
  await getCategories()
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.categories {
  padding-block: 100px;
  background-color: $white;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__title {
    width: fit-content;
    border-bottom: 3px solid rgba(143, 210, 238, 1);
    padding: 5px 8px 8px 8px;
    color: $dark-blue;
    font-size: 14px;
    font-weight: 700;
  }

  &__items {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
      width: 250px;
      transition: 0.2s;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }

  &__button {
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin-top: -15px;
    background-color: $blue;
    padding: 5px 15px;
    transition: 0.2s;

    font-size: 16px;
    color: $white;

    &:hover {
      background-color: $orange;
    }
  }
}

@media (max-width: 1300px) {
  .categories {

    &__items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 25px;
    }
  }
}

@media (max-width: 650px) {
  .categories {

    &__items {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 450px) {
  .categories {

    &__items {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
<template>
  <div class="add">
    <div class="add__form">
      <div class="add__block">
        <div class="add__name">Company</div>
        <div class="add__block-inner">
          <input
              type="text"
              placeholder="Company"
              class="add__block-input"
              v-model="company"
          >
        </div>
      </div>
      <div class="add__block">
        <div class="add__name required">Address 1</div>
        <div class="add__block-inner">
          <input
              type="text"
              placeholder="Address 1"
              class="add__block-input"
              v-model="address1"
              @input="onAddressInput"
          >
          <p class="add__block-error" v-if="addressError">{{ addressError }}</p>
        </div>
      </div>
      <div class="add__block">
        <div class="add__name">Address 2</div>
        <div class="add__block-inner">
          <input
              type="text"
              placeholder="Address 2"
              class="add__block-input"
              v-model="address2"
          >
        </div>
      </div>
      <div class="add__block">
        <div class="add__name required">Country</div>
        <div class="add__block-inner">
          <el-select
            v-model="country"
            placeholder="Country"
            style="width: 400px"
            filterable
            remote
            reserve-keyword
            remote-show-suffix
            :remote-method="getMoreCountries"
          >
            <el-option
              v-for="country in countriesList"
              :key="country.node.uuid"
              :label="country.node.name"
              :value="country.node.name"
            />
          </el-select>
        </div>
      </div>
      <div class="add__block">
        <div class="add__name required">Region / State</div>
        <div class="add__block-inner">
          <el-select
            v-model="region"
            placeholder="Region / State"
            style="width: 400px"
            filterable
            remote
            reserve-keyword
            remote-show-suffix
            :remote-method="getMoreRegions"
          >
            <el-option
              v-for="item in regionsList"
              :key="item.node.uuid"
              :label="item.node.name"
              :value="item.node.name"
            />
          </el-select>
        </div>
      </div>
      <div class="add__block">
        <div class="add__name required">City</div>
        <div class="add__block-inner">
          <el-select
            v-model="city"
            placeholder="City"
            style="width: 400px"
            filterable
            remote
            reserve-keyword
            remote-show-suffix
            :remote-method="getMoreCities"
          >
            <el-option
              v-for="item in citiesList"
              :key="item.node.uuid"
              :label="item.node.name"
              :value="item.node.uuid"
            />
          </el-select>
        </div>
      </div>
      <div class="add__block">
        <div class="add__name required">Post Code</div>
        <div class="add__block-inner">
          <el-select
            v-model="postCode"
            placeholder="Post Code"
            style="width: 400px"
            filterable
            remote
            reserve-keyword
            remote-show-suffix
            :remote-method="getMorePostCodes"
          >
            <el-option
              v-for="item in postCodesList"
              :key="item.node.uuid"
              :label="item.node.code"
              :value="item.node.uuid"
            />
          </el-select>
        </div>
      </div>
    </div>
    <div class="add__line"></div>
    <div class="add__buttons">
      <button @click="back" class="add__button red">
        <img class="rotate" src="@icons/arrowIcon.svg" alt="back">
        BACK
      </button>
      <button
        class="add__button"
        @click="addAddress"
        :disabled="!isFormValid"
      >
        CONTINUE
        <img src="@icons/arrowIcon.svg" alt="continue">
      </button>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {required} from "@/core/rules/textFieldRules.js";
import {Locations} from "@/core/api/locations.js";
import {Address} from "@/core/api/address.js";
import _ from 'lodash';
import {useAddressStore} from "@/stores/address.js";
import {useAppStore} from "@/stores/app.js";
import {useAuthStore} from "@/stores/auth.js";

const router = useRouter()
const addressStore = useAddressStore()
const authStore = useAuthStore()
const appStore = useAppStore()

const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})

const company = ref('')
const address1 = ref('')
const address2 = ref('')
const postCode = ref(null)
const country = ref(null)
const city = ref(null)
const region = ref(null)

const countriesList = ref([])
const regionsList = ref([])
const citiesList = ref([])
const postCodesList = ref([])

const addressError = ref('')

const onAddressInput = (e) => {
  if (required(e.target.value) !== true) {
    addressError.value = required(e.target.value)
  } else {
    addressError.value = ""
  }
}

const isFormValid = computed(() => {
  return (
    required(address1.value) === true &&
    required(postCode.value) === true &&
    required(country.value) === true &&
    required(city.value) === true &&
    required(region.value) === true
  )
})

const getCountries = async (name) => {
  const res = await Locations.getCountries(name)

  if (res.data) {
    countriesList.value = res.data.countries.edges
  }
}
const getRegions = async (name = '') => {
  const res = await Locations.getRegions(country.value, name)

  if (res.data) {
    regionsList.value = res.data.regions.edges
  }
}
const getCities = async (name = '') => {
  const res = await Locations.getCities(region.value, name)

  if (res.data) {
    citiesList.value = res.data.cities.edges
  }
}
const getPostCodes = async (code = '') => {
  const res = await Locations.getPostCodes(country.value, code)

  if (res.data) {
    postCodesList.value = res.data.postalCodes.edges
  }
}

const getMoreCountries = _.debounce(async (query) => {
  await getCountries(query);
}, 500);
const getMoreRegions = _.debounce(async (query) => {
  await getRegions(query);
}, 500);
const getMoreCities = _.debounce(async (query) => {
  await getCities(query);
}, 500);
const getMorePostCodes = _.debounce(async (query) => {
  await getPostCodes(query);
}, 500);

const back = () => {
  router.go(-1)
}

watch(
  () => country.value,
  async () => {
    await getRegions()
  },
  { deep: true }
)
watch(
  () => region.value,
  async () => {
    await getCities()
  },
  { deep: true }
)
watch(
  () => country.value,
  async () => {
    await getPostCodes()
  },
  { deep: true }
)

const addAddress = async () => {
  const res = await Address.createAddress(address1.value, postCode.value, city.value, accessToken.value)

  if (res.data) {
    const addressResult = await Address.getAddresses(accessToken.value)
    addressStore.setAddress(addressResult.data.addresses.edges)

    const popup = {
      text: 'You have successfully added a new address!',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}

onMounted( async () => {
  await getCountries()
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.add {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;

  &__title {
    text-transform: uppercase;
    color: $dark-blue;
    font-weight: 700;
    font-size: 16px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__form {
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  &__block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-input {
      width: 400px;
      height: 34px;
      box-shadow: 0 0 0 1px var(--el-border-color) inset;
      padding-inline: 12px;
      border-radius: 4px;

      color: #555;
      font-size: 14px;

      &:hover {
        border-color: rgba(221, 221, 221, 1);
      }
    }

    &-error {
      margin-top: 5px;
      color: $red;
      font-size: 12px;
    }
  }

  &__name {
    margin-top: 10px;
    display: flex;
    gap: 3px;

    font-size: 13px;
    color: rgba(35,56,81,1);
  }

  &__line {
    margin-top: 20px;
    height: 1px;
    width: 100%;
    background-color: rgba(221, 221, 221, 1);
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    cursor: pointer;
    align-self: flex-end;
    background-color: $blue;
    display: flex;
    align-items: center;
    gap: 3px;
    transition: 0.2s;
    border-radius: 3px;
    padding: 12px 15px;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }
}

.required {

  &::after {
    content: "*";
    color: rgba(232, 40, 77, 1);
  }
}

.rotate {
  transform: rotate(0.5turn);
}

.red {
  background-color: $red;

  &:hover {
    background-color: $light-blue;
  }
}

@media (max-width: 600px) {
  .add {
    &__form {
      width: 100%;
    }

    &__block {
      flex-direction: column;
      gap: 5px;

      &-inner {
        width: 100%;
      }

      &-input {
        width: 100%;
      }
    }
  }

  .el-select {
    width: 100% !important;
  }
}
</style>
<template>
  <div class="page" v-if="product">
    <h1 class="page__title">{{ product.name }}</h1>
    <div class="container">
      <div class="page__container">
        <div class="page__wrapper">
          <div class="page__gallery">
            <div class="page__images">
              <img
                class="page__image"
                :src="image.node.image"
                :alt="product.name"
                v-for="image in product.images.edges"
                :key="image.uuid"
                @click="selectImage(image.node.image)"
              >
            </div>
            <img class="page__images-big" :src="selectedImage" :alt="product.name">
          </div>
          <div class="page__main">
            <p class="page__price">{{ product.price }}€</p>
            <div class="page__main-block">
              <p class="page__main-text">Size</p>
              <div class="page__main-inner">
                <button
                  class="page__main-button"
                  :class="[{ active: selectedSize === size }]"
                  @click="selectSize(size)"
                  v-for="size in product.attributes.available_sizes"
                  :key="size"
                >
                  {{ size }}
                </button>
              </div>
            </div>
            <div class="page__main-block">
              <p class="page__main-text">Color</p>
              <div class="page__main-inner">
                <button
                  class="page__main-button"
                  :class="[{ active: selectedColor === color }]"
                  :style="{ 'background-color': color }"
                  @click="selectColor(color)"
                  v-for="color in product.attributes.available_colors"
                  :key="color"
                ></button>
              </div>
            </div>
            <div class="page__actions">
              <div class="page__actions-top">
                <div class="stepper">
                  <input
                    type="text"
                    class="stepper__input"
                    v-model="stepper"
                  >
                  <div class="stepper__buttons">
                    <div class="stepper__buttons-button" @click="stepper += 1">
                      <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z"
                              fill="currentColor"/>
                      </svg>
                    </div>
                    <div class="stepper__buttons-button" @click="stepper -= 1">
                      <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z"
                              fill="currentColor"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <button class="page__button" :class="[{ disabled: !selectedColor || !selectedSize}]" @click="addProductsToCart(stepper)">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.78571 5H18.2251C19.5903 5 20.5542 6.33739 20.1225 7.63246L18.4558 12.6325C18.1836 13.4491 17.4193 14 16.5585 14H6.07142M4.78571 5L4.74531 4.71716C4.60455 3.73186 3.76071 3 2.76541 3H2M4.78571 5L6.07142 14M6.07142 14L6.25469 15.2828C6.39545 16.2681 7.23929 17 8.23459 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM11 19C11 20.1046 10.1046 21 9 21C7.89543 21 7 20.1046 7 19C7 17.8954 7.89543 17 9 17C10.1046 17 11 17.8954 11 19Z"
                          stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  ADD TO CART
                </button>
              </div>
              <button class="page__wishlist" @click="addProductToWishlist">
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12 6.00019C10.2006 3.90317 7.19377 3.2551 4.93923 5.17534C2.68468 7.09558 2.36727 10.3061 4.13778 12.5772C5.60984 14.4654 10.0648 18.4479 11.5249 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
                      stroke="#e8284d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                ADD TO WISHLIST
              </button>
            </div>
          </div>
          <Swiper
            :modules="[Autoplay]"
            :autoplay="{
              delay: 5000,
              disableOnInteraction: false,
            }"
            :space-between="20"
            :slides-per-view="1"
            :loop="true"
            :speed="500"
            class="page__main-swiper"
          >
            <Swiper-slide
              v-for="tagProduct in tagProducts"
              :key="tagProduct.node.uuid"
            >
              <product-card
                :width="195"
                :product="tagProduct.node"
              />
            </Swiper-slide>
          </Swiper>
        </div>
        <div class="page__description">
          <p class="page__description-title">Description</p>
          <div class="page__description-texts" v-html="productDescription"></div>
        </div>
      </div>
    </div>
    <div class="page__swipers">
      <div class="container">
        <div class="page__swipers-wrapper">
          <div class="page__swipers-block">
            <p class="page__swipers-title">You May also like</p>
            <Swiper
              :modules="[Autoplay]"
              :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
              }"
              :slides-per-view="3"
              :loop="true"
              :speed="500"
              class="swiper"
            >
              <Swiper-slide
                v-for="alsoLikeProduct in alsoLikeProducts"
                :key="alsoLikeProduct.node.uuid"
              >
                <product-card
                  :width="195"
                  :product="alsoLikeProduct.node"
                />
              </Swiper-slide>
            </Swiper>
          </div>
          <div class="page__swipers-block">
            <p class="page__swipers-title">Special offers</p>
            <Swiper
              :modules="[Autoplay]"
              :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
              }"
              :slides-per-view="3"
              :loop="true"
              :speed="500"
              class="swiper"
            >
              <Swiper-slide
                v-for="alsoLikeProduct in specialProducts"
                :key="alsoLikeProduct.node.uuid"
              >
                <product-card
                  :width="195"
                  :product="alsoLikeProduct.node"
                />
              </Swiper-slide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import 'swiper/css';
import {Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/vue";
import ProductCard from "@/components/product/product-card.vue";
import {Products} from "@/core/api/products.js";
import {useRoute} from "vue-router";
import {computed, onMounted, ref, watch} from "vue";
import {Wishlist} from "@/core/api/wishlist.js";
import {useWishlistStore} from "@/stores/wishlist.js";
import {useAuthStore} from "@/stores/auth.js";
import {useCartStore} from "@/stores/cart.js";
import {Orders} from "@/core/api/order.js";
import {useAuthModalsStore} from "@/stores/authModals.js";
import {useAppStore} from "@/stores/app.js";

const authStore = useAuthStore()
const cartStore = useCartStore()
const authModalsStore = useAuthModalsStore()
const wishlistStore = useWishlistStore()
const appStore = useAppStore()
const route = useRoute()

const user = computed(() => authStore.user)
const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})
const cartId = computed(() => {
  return cartStore.currentOrder ? cartStore.currentOrder.uuid : null
})
const productId = computed(() => {
  return route.params.productId ? route.params.productId : null
})

const product = ref(null)
const tagProducts = ref([])
const alsoLikeProducts = ref([])
const specialProducts = ref([])

const productDescription = computed(() => {
    return product.value.description;
});

const stepper = ref(1)

const selectedSize = ref('')
const selectSize = (size) => {
    selectedSize.value = size
}

const selectedColor = ref('')
const selectColor = (color) => {
    selectedColor.value = color
}

const selectedImage = ref(null)
const selectImage = (imgSrc) => {
    selectedImage.value = imgSrc
}

const addProductsToCart = async (stepperValue) => {
  if (selectedSize.value && selectedColor.value) {
    let successful = true;
    for (let i = 0; i < stepperValue; i++) {
      const res = await addProductToCartFunction();
      if (!res.data.addProductToPendingOrder.order) {
        successful = false;
      }
    }
    if (successful) {
      const popup = {
        text: 'You have successfully added the products to the cart!',
        tag: 'success',
        id: Math.random() * 2000
      }
      appStore.popupArray.unshift(popup)
      appStore.setPopupVisible(true)
    } else {
      const popup = {
        text: 'Something went wrong!',
        tag: 'fail',
        id: Math.random() * 2000
      }
      appStore.popupArray.unshift(popup)
      appStore.setPopupVisible(true)
    }
  } else {
    const popup = {
      text: 'You need to choose the options!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}

const addProductToCartFunction = async () => {
  if (user.value) {
    const res = await Orders.overwriteCart("addProductToPendingOrder", accessToken.value, cartId.value, productId.value, selectedColor.value, selectedSize.value)

    if (res.data.addProductToPendingOrder.order) {
      cartStore.currentOrder = res.data.addProductToPendingOrder.order;
    }
    return res;
  } else {
     authModalsStore.setSignIn(true)
  }
}

const addProductToWishlist = async () => {
  const res = await Wishlist.overwriteWishlist("addProductToWishlist", productId.value, wishlistStore.wishlist.uuid, accessToken.value)

  if (res.data.addProductToWishlist.wishlist) {
    wishlistStore.wishlist = res.data.addProductToWishlist.wishlist

    const popup = {
      text: 'You have successfully added the product to wishlist!',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}

const getProductById = async () => {
  const res = await Products.getProductById(productId.value)
  product.value = res.data.products.edges[0].node

  selectedImage.value = product.value.images.edges[0].node.image
}

const getProducts = async () => {
  alsoLikeProducts.value = []
  specialProducts.value = []

  const res1 = await Products.getAllProducts(0,  '0', '100', 'random', product.value.category.name,'', 9, '', '')
  alsoLikeProducts.value = res1.data.products.edges

  const res2 = await Products.getProductByTag('special_offers',  '0', '100', '', product.value.category.name,'', 9, '', '')
  specialProducts.value = res2.data.products.edges
}

const getProductsByTag = async () => {
  const res = await Products.getProductByTag('trend')
  tagProducts.value = res.data.products.edges
}

watch(
  () => productId.value,
  async () => {
    await getProductById()
    await getProductsByTag()
    await getProducts()
  },
  { deep: true }
)

onMounted( async () => {
  await getProductById()
  await getProductsByTag()
  await getProducts()
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__container {
    margin-block: 25px 50px;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__gallery {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  &__images {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-big {
      width: 324px;
      aspect-ratio: 1;
    }
  }

  &__image {
    cursor: pointer;
    width: 68px;
    aspect-ratio: 1;
    border: 1px solid rgba(221, 221, 221, 1);
  }

  &__main {
    width: 40%;
    height: 324px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-swiper {
      width: 195px !important;
      margin-inline: unset !important;
    }

    &-block {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &-text {
      font-size: 13px;
      color: rgba(35, 56, 81, 1);

      &::after {
        content: "*";
        color: rgba(232, 40, 77, 1);
      }
    }

    &-inner {
      display: flex;
      align-items: center;
      gap: 13px;
    }

    &-button {
      cursor: pointer;
      border: 2px solid rgba(221, 221, 221, 1);
      background-color: rgba(237, 242, 254, 1);
      height: 30px;
      padding-inline: 10px;
      transition: 0.2s;

      font-size: 13px;
      color: rgba(35, 56, 81, 1);

      &:hover {
        border-color: rgba(143, 210, 238, 1);
        background-color: rgba(187, 198, 221, 1);
        box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.15);
      }
    }
  }

  &__price {
    font-size: 32px;
    font-weight: 700;
    color: rgba(35,56,81,1);
  }

  &__actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &-top {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  &__button {
    cursor: pointer;
    width: 100%;
    background-color: rgba(6, 30, 187, 1);
    border-radius: 2px;
    padding-block: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    transition: 0.2s;

    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }
  }

  &__buy {
    cursor: pointer;
    background-color: $green;
    padding: 10px 10px;
    border-radius: 2px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    white-space: nowrap;
    font-size: 12px;
    color: rgba(255,255,255,1);
    font-weight: 700;

    &:hover {
      background-color: $light-blue;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;

    &-title {
      color: $dark-blue;
      font-weight: 700;
      font-size: 16px;

      &::after {
        content: '';
        width: 50px;
        height: 3px;
        background-color: $light-blue;
        display: block;
        position: relative;
        margin-top: 7px;
      }
    }

    &-text {
      color: rgb(85, 85, 85);
      font-size: 15px;
    }
  }

  &__wishlist {
    width: 100%;
    cursor: pointer;
    border: 1px solid rgba(35, 56, 81, 1);
    padding-block: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: transparent;
    border-radius: 2px;
    transition: 0.2s;

    color: rgba(35, 56, 81, 1);
    font-weight: 700;
    font-size: 12px;

    &:hover {
      color: rgba(248, 86, 57, 1);
      background: none;
    }
  }

  &__swipers {
    background-color: rgba(238, 238, 238, 1);
    padding-block: 40px;

    &-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &-block {
      width: 49%;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &-title {
      text-transform: uppercase;
      color: $dark-blue;
      font-weight: 700;
      font-size: 16px;

      &::after {
        content: '';
        width: 50px;
        height: 3px;
        background-color: $orange;
        display: block;
        position: relative;
        margin-top: 7px;
      }
    }
  }
}

.swiper {
  width: 100%;
}

.active {
  box-shadow: inset 0 0 15px -5px rgba(0, 0, 0, 0.4);
  border-color: rgba(143, 210, 238, 1);
  background: rgba(187, 198, 221, 1);
}

.stepper {
  width: fit-content;
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid $blue;

  &__input {
    width: 32px;
    height: 100%;
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0 2px 15px -2px;

    text-align: center;
    color: #555;
    font-size: 15px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    width: 26px;
    height: 100%;
    border-left: 1px solid $blue;

    &-button {
      height: 20px;
      cursor: pointer;
      display: grid;
      place-items: center;
      transition: 0.2s;

      color: $blue;

      &:hover {
        color: rgba(143,210,238,1);
      }

      &:first-child {
        border-bottom: 1px solid $blue;
      }
    }
  }
}

.disabled {
  background-color: $dark-grey;
  cursor: not-allowed;

  &:hover {
    background-color: $dark-grey;
  }
}
</style>
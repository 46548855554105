<template>
  <div class="contact">
    <h2 class="contact__title">LEAVE US A MESSAGE</h2>
    <div class="contact__block">
      <div class="contact__name required">Your Name</div>
      <div class="contact__block-inner">
        <input
            type="text"
            placeholder="Your Name"
            class="contact__block-input"
            v-model="name"
            @input="onNameInput"
        >
        <p class="contact__block-error" v-if="nameError">{{ nameError }}</p>
      </div>
    </div>
    <div class="contact__block">
      <div class="contact__name required">Your Email</div>
      <div class="contact__block-inner">
        <input
            type="text"
            placeholder="Your Email"
            class="contact__block-input"
            v-model="email"
            @input="onEmailInput"
        >
        <p class="contact__block-error" v-if="emailError">{{ emailError }}</p>
      </div>
    </div>
    <div class="contact__block textarea-block">
      <div class="contact__name required">Message</div>
      <div class="contact__block-inner">
        <textarea
            type="text"
            placeholder="Message"
            class="contact__block-input textarea"
            v-model="message"
            @input="onMessageInput"
        />
        <p class="contact__block-error" v-if="messageError">{{ messageError }}</p>
      </div>
    </div>
    <div class="contact__line"></div>
    <button
        class="contact__button"
        @click="sendMessage"
        :disabled="!isFormValid"
    >
      SUBMIT
      <img src="@icons/arrowIcon.svg" alt="contact">
    </button>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {required} from "@/core/rules/textFieldRules.js";
import {useAppStore} from "@/stores/app.js";

const appStore = useAppStore()

const name = ref('')
const email = ref('')
const message = ref('')

const nameError = ref('')
const emailError = ref('')
const messageError = ref('')

const onNameInput = (e) => {
  if (required(e.target.value) !== true) {
    nameError.value = required(e.target.value)
  } else {
    nameError.value = ""
  }
}
const onEmailInput = (e) => {
  if (required(e.target.value) !== true) {
    emailError.value = required(e.target.value)
  } else {
    emailError.value = ""
  }
}
const onMessageInput = (e) => {
  if (required(e.target.value) !== true) {
    messageError.value = required(e.target.value)
  } else {
    messageError.value = ""
  }
}

const isFormValid = computed(() => {
  return (
    required(name.value) &&
    required(email.value) &&
    required(message.value) === true
  )
})

const sendMessage = () => {
  const popup = {
    text: 'You have successfully send us a message!',
    tag: 'success',
    id: Math.random() * 2000
  }
  appStore.popupArray.unshift(popup)
  appStore.setPopupVisible(true)
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.contact {
  width: 768px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  background-color: #eee;
  padding: 20px;
  border-radius: 3px;

  &__title {
    color: rgba(35,56,81,1);
    font-weight: 700;
    font-size: 15px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-input {
      resize: none;
      width: 450px;
      height: 34px;
      border: 1px solid rgba(238, 238, 238, 1);
      padding-inline: 12px;

      color: #555;
      font-size: 14px;

      &:hover {
        border-color: rgba(221, 221, 221, 1);
      }
    }

    &-radio {
      cursor: pointer;
    }

    &-error {
      margin-top: 5px;
      color: $red;
      font-size: 12px;
    }
  }

  &__name {
    display: flex;
    gap: 3px;

    font-size: 13px;
    color: rgba(35,56,81,1);
  }

  &__line {
    margin-top: 20px;
    height: 1px;
    width: 100%;
    background-color: rgba(221, 221, 221, 1);
  }

  &__button {
    cursor: pointer;
    align-self: flex-end;
    background-color: $blue;
    display: flex;
    align-items: center;
    gap: 3px;
    transition: 0.2s;
    border-radius: 3px;
    padding: 12px 15px;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }
}

.required {

  &::after {
    content: "*";
    color: rgba(232, 40, 77, 1);
  }
}

.textarea {
  padding-top: 5px;
  height: 150px;

  &-block {
    align-items: flex-start;
  }
}

@media (max-width: 800px) {
  .contact {
    width: 315px;

    &__block {
      flex-direction: column;
      gap: 5px;

      &-input {
        width: 260px;
      }
    }
  }

  .textarea {
    &-block {
      align-items: center;
    }
  }
}
</style>
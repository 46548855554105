<template>
  <div class="page">
    <h1 class="page__title">License agreement</h1>
    <div class="container">
      <div class="page__wrapper">
        <p class="page__text">The following Terms of Service ("TOS") constitute a legal agreement between you (the individual, employer, or entity entering into the agreement) and {{ companyName }}. This agreement outlines the rights and responsibilities concerning any content you license from {{ companyName }}. You are encouraged to review these TOS whenever you purchase content licenses. {{ companyName }} reserves the right to modify the TOS at any time. Notice of changes will be provided via email, announcements, or other reasonable means, and any modifications will apply to future purchases. By continuing to license content after such changes, you agree to the updated TOS.</p>
        <p class="page__text">"Images" refer to still photos, vectors, drawings, graphics, and similar visual content.</p>
        <div class="page__block">
          <p class="page__subtitle">PART I – VISUAL CONTENT LICENSES</p>
          <p class="page__text">{{ companyName }} grants you a non-exclusive, non-transferable right to use and modify the images under the terms of the license purchased, subject to the limitations below:</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">IMAGE LICENSE USE:</p>
          <p class="page__text">You are permitted to use images:</p>
          <ul class="page__ul">
            <li class="page__text">Digitally, including on websites, in online advertising, mobile apps, and social media.</li>
            <li class="page__text">Printed for physical products like packaging, business cards, and advertising in tangible media (e.g., magazines).</li>
            <li class="page__text">In Out-of-Home advertising (e.g., billboards).</li>
            <li class="page__text">In audiovisual productions (e.g., films, ads) for distribution in any medium.</li>
            <li class="page__text">For personal, non-commercial purposes.</li>
            <li class="page__text">On merchandise for sale or promotional distribution.</li>
            <li class="page__text">As decorative wall art in commercial spaces.</li>
            <li class="page__text">Within digital templates for sale or distribution.</li>
          </ul>
        </div>
        <div class="page__block">
          <p class="page__subtitle">RESTRICTIONS:</p>
          <p class="page__text">You are prohibited from:</p>
          <ul class="page__ul">
            <li class="page__text">Using content beyond the purchased license.</li>
            <li class="page__text">Portraying models in offensive or inappropriate contexts (e.g., pornographic, political, defamatory).</li>
            <li class="page__text">Reselling, sharing, or redistributing content unless permitted.</li>
            <li class="page__text">Using content in a manner that infringes third-party rights.</li>
            <li class="page__text">Falsely representing content ownership.</li>
          </ul>
        </div>
        <div class="page__block">
          <p class="page__subtitle">PART II – WARRANTIES AND REPRESENTATIONS</p>
          <p class="page__text">{{ companyName }} warrants that its contributors have the rights to the content, and when used in accordance with the TOS and applicable law, the content will not infringe any intellectual property rights or violate privacy laws. {{ companyName }} makes no warranties beyond those explicitly stated in this section.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">PART III – INDEMNIFICATION AND LIABILITY</p>
          <p class="page__text">{{ companyName }} will indemnify you up to EUR 10,000 for direct damages arising from a breach of warranties, provided you promptly notify {{ companyName }} of any claims. You are required to cooperate with {{ companyName }} in defending such claims. {{ companyName }} is not responsible for damages arising from content modifications or misuse.</p>
          <p class="page__text">You agree to indemnify {{ companyName }} for any damages arising from unauthorized use of the content.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">PART IV – ADDITIONAL TERMS</p>
          <p class="page__text">Refunds are not guaranteed, and all fees are non-refundable unless determined otherwise by {{ companyName }}. Content licenses are non-transferable, except for one-time transfers to third parties for product manufacturing. If content is subject to a legal claim, you must cease using it and remove it from your systems.</p>
          <p class="page__text">If you use content for client work, you must disclose the client’s identity upon {{ companyName }}'s request. Disputes will be settled through individual arbitration under UK law, with certain exceptions for intellectual property disputes or urgent injunctions. Neither party may bring class action claims.</p>
          <p class="page__text">{{ companyName }} may terminate your account if you breach any terms, without refunding fees. The company does not guarantee uninterrupted or error-free use of content or websites.</p>
          <p class="page__text">This agreement is for the mutual benefit of the parties and does not grant third-party rights.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useCompanyStore} from "@/stores/company.js";
import {computed} from "vue";

const companyStore = useCompanyStore()

const companyName = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo.companyName : ''
})
const companyEmail = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo.companyEmail : ''
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-block: 50px;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__subtitle {
    font-size: 16px;
    color: rgba(35,56,81,1);
    font-weight: 700;
  }

  &__ul {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(35,56,81,1);
  }

  &__link {
    font-weight: 700;
  }
}
</style>
const isEmail = (email) => {
    if (!email) return required(email);
    if (/.+@.+\..+/.test(email)) return true
    // const { t } = i18n.global
    // return t('errors.mail')
    return 'Email must be valid!'
}

const isEmailNoReq = (email) => {
    if (!email) return true;
    if (/.+@.+\..+/.test(email)) return true
    // const { t } = i18n.global
    // return t('errors.mail')
    return 'Email must be valid!'
}

const required = (text) => {
    if (text) return true
    // const { t } = i18n.global
    // return t('errors.required')
    return 'This field is required!'
}

const isPasswordValid = (pass) => {
    // const { t } = i18n.global

    if (pass.length < 8) {
        // return t('errors.needMin')
        return 'Min. 8 characters'
    }

    if (!/[a-z]/.test(pass)) {
        // return t('errors.needLower')
        return 'Please include lowercase letter.'
    }

    if (!/[A-Z]/.test(pass)) {
        // return t('errors.needUpper')
        return 'Please include uppercase letter.'
    }

    if (!/\d/.test(pass)) {
        // return t('errors.needNumber')
        return 'Please include number.'
    }

    if (!/[#.?!@$%^&*'()_+=:;"'/>.<,|\-]/.test(pass)) {
        // return t('errors.needSpecial')
        return 'Please include a special character: #.?!$%^&*\'()_+=:;\"\'/>.<,|\\-'
    }

    return true
}

export { required, isEmail, isEmailNoReq, isPasswordValid }
<template>
  <tr>
    <td class="page__table-text"><img :src="product.node.product.images.edges[0].node.image" :alt="product.node.product.name"></td>
    <td class="page__table-text">
      <div class="page__table-inner">
        {{ product.node.product.name }}
        <p v-if="product.node.attributes">Size: {{ product?.node.attributes.size }}</p>
        <div>
          <p v-if="product.node.attributes">Color: </p>
          <div class="page__table-color" :style="{ 'background-color': product?.node.attributes?.color }"></div>
        </div>
      </div>
    </td>
    <td class="page__table-buttons">
      <div class="stepper">
        <input
            type="text"
            class="stepper__input"
            v-model="product.stepper"
        >
        <div class="stepper__buttons">
          <div class="stepper__buttons-button" @click="addProductToCart(product.node.product.uuid, product?.node.attributes?.color, product?.node.attributes?.size)">
            <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.2929 15.2893C18.6834 14.8988 18.6834 14.2656 18.2929 13.8751L13.4007 8.98766C12.6195 8.20726 11.3537 8.20757 10.5729 8.98835L5.68257 13.8787C5.29205 14.2692 5.29205 14.9024 5.68257 15.2929C6.0731 15.6835 6.70626 15.6835 7.09679 15.2929L11.2824 11.1073C11.673 10.7168 12.3061 10.7168 12.6966 11.1073L16.8787 15.2893C17.2692 15.6798 17.9024 15.6798 18.2929 15.2893Z"
                    fill="currentColor"/>
            </svg>
          </div>
          <div class="stepper__buttons-button" @click="removeProductFromCart(product.node.product.uuid, product?.node.attributes?.color, product?.node.attributes?.size)">
            <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z"
                    fill="currentColor"/>
            </svg>
          </div>
        </div>
      </div>
      <button @click="removeAllProductsFromCart(product.node.product.uuid, product?.node.attributes?.color, product?.node.attributes?.size)" class="page__table-button"><img src="@icons/crossIcon.svg" alt="delete"></button>
    </td>
    <td class="page__table-text">{{ product.node.product.price }}€</td>
    <td class="page__table-text">
      {{ product.node.buyPrice }}€
    </td>
  </tr>
</template>

<script setup>
import {Orders} from "@/core/api/order.js";
import {useCartStore} from "@/stores/cart.js";
import {useAppStore} from "@/stores/app.js";
import {computed} from "vue";
import {useAuthStore} from "@/stores/auth.js";

const cartStore = useCartStore()
const appStore = useAppStore()
const authStore = useAuthStore()

const props = defineProps({
  product: Object
})

const cartId = computed(() => {
  return cartStore.currentOrder ? cartStore.currentOrder.uuid : null
})
const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})

const removeAllProductsFromCart = async (productId, color, size) => {
  const res = await Orders.overwriteCart("removeAllProductsOfAKindFromPendingOrder", accessToken.value, cartId.value, productId, color, size )

  if (res.data.removeAllProductsOfAKindFromPendingOrder) {
    cartStore.currentOrder = res.data.removeAllProductsOfAKindFromPendingOrder.order

    const popup = {
      text: 'You have successfully removed the product from cart!',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}

const removeProductFromCart = async (productId, color, size) => {
  const res = await Orders.overwriteCart("removeProductFromPendingOrder", accessToken.value, cartId.value, productId, color, size )

  if (res.data.removeProductFromPendingOrder) {
    cartStore.currentOrder = res.data.removeProductFromPendingOrder.order

    const popup = {
      text: 'You have successfully removed the product from cart!',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}

const addProductToCart = async (productId, color, size) => {
  const res = await Orders.overwriteCart("addProductToPendingOrder", accessToken.value, cartId.value, productId, color, size )

  if (res.data.addProductToPendingOrder) {
    cartStore.currentOrder = res.data.addProductToPendingOrder.order;

    const popup = {
      text: 'You have successfully added the products to the cart!',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  &__table {

    width: 100%;
    border: 1px solid rgba(221,221,221,1);
    border-collapse: collapse;

    & tr {
      border-bottom: 1px solid rgba(221,221,221,1);
    }

    & th {
      padding: 8px 14px;
    }

    &-text {
      text-align: center;

      & img {
        width: 90px;
      }
    }

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;

      & div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    &-color {
      width: 15px;
      height: 20px;
      border: 2px solid rgba(221, 221, 221, 1);
    }

    &-buttons {
      height: 97px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    &-button {
      cursor: pointer;
      width: 36px;
      aspect-ratio: 1;
      display: grid;
      place-items: center;
      background-color: $blue;
      border-radius: 2px;
      transition: 0.2s;
      color: $white;

      & img {
        width: 25px;
        aspect-ratio: 1;
      }

      &:hover {
        background-color: $orange;
      }

      &:last-child {
        background-color: $red;

        &:hover {
          background-color: $light-blue;
        }
      }
    }
  }
}

.stepper {
  width: 55px;
  display: flex;
  align-items: center;
  height: 30px;
  border: 1px solid $blue;

  &__input {
    width: 32px;
    height: 100%;
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0 2px 15px -2px;

    text-align: center;
    color: #555;
    font-size: 15px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    width: 26px;
    height: 100%;
    border-left: 1px solid $blue;

    &-button {
      height: 15px;
      cursor: pointer;
      display: grid;
      place-items: center;
      transition: 0.2s;

      color: $blue;

      &:hover {
        color: rgba(143,210,238,1);
      }

      &:first-child {
        border-bottom: 1px solid $blue;
      }
    }
  }
}
</style>
<template>
  <div class="page">
    <h1 class="page__title">Store</h1>
    <div class="container">
      <div class="page__wrapper">
        <filter-component @clearFilters="clearFilters" @filterPrice="onFilterPrice" @filterCategory="onFilterCategory" @filterSize="onFilterSize" @filterColor="onFilterColor" @filterTag="onFilterTag" />
        <div class="page__main">
          <div class="page__block">
            <div class="page__block-left">
              <svg @click="isGrid = true" :class="{active: isGrid}" class="page__block-icon" width="14" height="18" viewBox="0 -0.5 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Dribbble-Light-Preview" transform="translate(-219.000000, -200.000000)" fill="currentColor">
                    <g id="icons" transform="translate(56.000000, 160.000000)">
                      <path d="M181.9,54 L179.8,54 C178.63975,54 177.7,54.895 177.7,56 L177.7,58 C177.7,59.105 178.63975,60 179.8,60 L181.9,60 C183.06025,60 184,59.105 184,58 L184,56 C184,54.895 183.06025,54 181.9,54 M174.55,54 L172.45,54 C171.28975,54 170.35,54.895 170.35,56 L170.35,58 C170.35,59.105 171.28975,60 172.45,60 L174.55,60 C175.71025,60 176.65,59.105 176.65,58 L176.65,56 C176.65,54.895 175.71025,54 174.55,54 M167.2,54 L165.1,54 C163.93975,54 163,54.895 163,56 L163,58 C163,59.105 163.93975,60 165.1,60 L167.2,60 C168.36025,60 169.3,59.105 169.3,58 L169.3,56 C169.3,54.895 168.36025,54 167.2,54 M181.9,47 L179.8,47 C178.63975,47 177.7,47.895 177.7,49 L177.7,51 C177.7,52.105 178.63975,53 179.8,53 L181.9,53 C183.06025,53 184,52.105 184,51 L184,49 C184,47.895 183.06025,47 181.9,47 M174.55,47 L172.45,47 C171.28975,47 170.35,47.895 170.35,49 L170.35,51 C170.35,52.105 171.28975,53 172.45,53 L174.55,53 C175.71025,53 176.65,52.105 176.65,51 L176.65,49 C176.65,47.895 175.71025,47 174.55,47 M167.2,47 L165.1,47 C163.93975,47 163,47.895 163,49 L163,51 C163,52.105 163.93975,53 165.1,53 L167.2,53 C168.36025,53 169.3,52.105 169.3,51 L169.3,49 C169.3,47.895 168.36025,47 167.2,47 M181.9,40 L179.8,40 C178.63975,40 177.7,40.895 177.7,42 L177.7,44 C177.7,45.105 178.63975,46 179.8,46 L181.9,46 C183.06025,46 184,45.105 184,44 L184,42 C184,40.895 183.06025,40 181.9,40 M174.55,40 L172.45,40 C171.28975,40 170.35,40.895 170.35,42 L170.35,44 C170.35,45.105 171.28975,46 172.45,46 L174.55,46 C175.71025,46 176.65,45.105 176.65,44 L176.65,42 C176.65,40.895 175.71025,40 174.55,40 M169.3,42 L169.3,44 C169.3,45.105 168.36025,46 167.2,46 L165.1,46 C163.93975,46 163,45.105 163,44 L163,42 C163,40.895 163.93975,40 165.1,40 L167.2,40 C168.36025,40 169.3,40.895 169.3,42"
                        id="grid-[#1526]">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
              <svg @click="isGrid = false" :class="{active: !isGrid}" class="page__block-icon" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 6.00067L21 6.00139M8 12.0007L21 12.0015M8 18.0007L21 18.0015M3.5 6H3.51M3.5 12H3.51M3.5 18H3.51M4 6C4 6.27614 3.77614 6.5 3.5 6.5C3.22386 6.5 3 6.27614 3 6C3 5.72386 3.22386 5.5 3.5 5.5C3.77614 5.5 4 5.72386 4 6ZM4 12C4 12.2761 3.77614 12.5 3.5 12.5C3.22386 12.5 3 12.2761 3 12C3 11.7239 3.22386 11.5 3.5 11.5C3.77614 11.5 4 11.7239 4 12ZM4 18C4 18.2761 3.77614 18.5 3.5 18.5C3.22386 18.5 3 18.2761 3 18C3 17.7239 3.22386 17.5 3.5 17.5C3.77614 17.5 4 17.7239 4 18Z"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="page__block-right">
              <div class="page__block-inner">
                <span>Sort By:</span>
                <el-select
                  v-model="sortBy"
                  style="width: 119px"
                >
                  <el-option
                    v-for="item in sortByOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
            </div>
          </div>
          <div v-if="isGrid" class="page__grid">
            <product-card
              v-if="products.length"
              :width="250"
              v-for="product in products"
              :key="product.node.uuid"
              :product="product.node"
            />
            <product-card-skeleton
              v-for="idx in 12"
              v-if="isLoading"
            />
          </div>
          <div class="page__list" v-else>
            <product-item
              v-if="products.length"
              v-for="product in products"
              :key="product.node.uuid"
              :product="product.node"
            />
            <product-item-skeleton
              v-for="idx in 12"
              v-if="isLoading"
            />
          </div>
          <div ref="list" v-if="!isLoading && products.length > 0" class="page__observer"></div>
          <p v-if="!isLoading && products.length === 0" class="page__noProducts">There are no items with these parameters :(</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import FilterComponent from "@/components/store/filter-component.vue";
import {computed, onMounted, ref, watch} from "vue";
import ProductCard from "@/components/product/product-card.vue";
import {Products} from "@/core/api/products.js";
import {useRoute} from "vue-router";
import ProductItem from "@/components/product/product-item.vue";
import ProductCardSkeleton from "@/components/skeletons/product-card-skeleton.vue";
import ProductItemSkeleton from "@/components/skeletons/product-item-skeleton.vue";

const route = useRoute()

const isAll = ref(false)
const isGrid = ref(true)

const sortBy = ref(route.query['orderBy'] ? (route.query['orderBy']) : '')
const sortByOptions = [
  {
    value: '',
    label: 'Default',
  },
  {
    value: 'name',
    label: 'Name (A-Z)',
  },
  {
    value: '-name',
    label: 'Name (Z-A)',
  },
  {
    value: 'price',
    label: 'Price (Low > High)',
  },
  {
    value: '-price',
    label: 'Price (High > Low)',
  }
]

const list = ref(null)
const products = ref([])
const cursorForAfter = ref('0')
const isLoading = ref(true)

const price = ref([0, 100])
const categories = ref(route.query['categories'] ? (route.query['categories']) : [])
const sizes = ref(route.query['size'] ? (route.query['size']) : [])
const colors = ref(route.query['color'] ? (route.query['color']) : [])
const tags = ref(route.query['tags'] ? (route.query['tags']) : [])

const onFilterPrice = (filteredPrice) => {
  price.value = filteredPrice;
};
const onFilterCategory = (filteredCategory) => {
  categories.value = filteredCategory
}
const onFilterSize = (filteredSize) => {
  sizes.value = filteredSize
}
const onFilterColor = (filteredColor) => {
  colors.value = filteredColor
}
const onFilterTag = (filteredTag) => {
  tags.value = filteredTag
}

let observer
const createObserver = () => {
  if (observer) {
    observer.disconnect()
  }

  observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting && !isAll.value) {
        getMoreProducts()
      }
    },
    { threshold: 1 }
  )

  if (list.value !== null) {
    observer.observe(list.value)
  }
}
watch(list, createObserver)

const query = computed(() => {
  return `?after=${cursorForAfter.value}&minPrice=${price.value[0]}&maxPrice=${price.value[1]}&orderBy=${sortBy.value}&categories=${categories.value}&tags=${tags.value}&size=${sizes.value}&color=${colors.value}`
})

const clearFilters = (price, categories, sizes, colors, tags) => {
  price.value = price
  categories.value = categories
  sizes.value = sizes
  colors.value = colors
  tags.value = tags
}

const getProducts = async () => {
  isLoading.value = true
  products.value = []
  cursorForAfter.value = '0'

  try {
    const res = await Products.getAllProducts(cursorForAfter.value, price.value[0], price.value[1], sortBy.value, categories.value, tags.value, "12", sizes.value, colors.value)

    if (res.data.products.edges.length > 0) {
      products.value = res.data.products.edges
    }

    isLoading.value = false
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false
  }
}

const getMoreProducts = async () => {
  isLoading.value = true

  cursorForAfter.value = products.value[products.value.length - 1].cursor

  const res = await Products.getAllProducts(cursorForAfter.value, price.value[0], price.value[1], sortBy.value, categories.value, tags.value, "12", sizes.value, colors.value)

  if (res.data.products.edges.length === 0) {
    isAll.value = true
  } else {
    products.value.push(...res.data.products.edges)
  }

  isLoading.value = false
}

watch(
() => price.value,
async () => {
  history.pushState({}, '', route.path + query.value)
  await getProducts()
},
{ deep: true }
)
watch(
  () => categories.value,
  async () => {
    history.pushState({}, '', route.path + query.value)
    await getProducts()
  },
  { deep: true }
)
watch(
  () => tags.value,
  async () => {
    history.pushState({}, '', route.path + query.value)
    await getProducts()
  },
  { deep: true }
)
watch(
  () => sizes.value,
  async () => {
    history.pushState({}, '', route.path + query.value)
    await getProducts()
  },
  { deep: true }
)
watch(
  () => colors.value,
  async () => {
    history.pushState({}, '', route.path + query.value)
    await getProducts()
  },
  { deep: true }
)

watch(
  () => sortBy.value,
  async () => {
    history.pushState({}, '', route.path + query.value)
    await getProducts()
  },
  { deep: true }
)

onMounted( async () => {
  createObserver()
  await getProducts()
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);
    text-transform: capitalize;

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    padding-block: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__main {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &__block {
    width: 100%;
    background: rgba(245, 245, 245, 1);
    padding: 10px 20px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
      display: flex;
      align-items: center;
      gap: 15px;
    }

    &-icon {
      cursor: pointer;
      transition: 0.2s;
      color: #233851;

      &:hover {
        color: $light-blue;
      }
    }

    &-link {
      display: flex;
      align-items: center;
      gap: 5px;
      transition: 0.2s;

      color: #337ab7;

      & span {
        width: 20px;
        height: 20px;
        background-color: $red;
        border-radius: 50%;
        display: grid;
        place-items: center;

        color: $white;
        font-size: 10px;
      }

      &:hover {
        color: $orange;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-inner {
      display: flex;
      align-items: center;

      & span {
        padding: 8px 10px;
        border-radius: 3px;
        background: rgba(221, 221, 221, 1);

        white-space: nowrap;
        font-size: 12px;
        color: #555;
        text-align: center;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 40px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__noProducts {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: rgb(35, 56, 81);
  }

  &__observer {
    width: 100%;
  }
}

.active {
  color: $light-blue;
}

@media (max-width: 1680px) {
  .page {
    &__wrapper {
      gap: 50px;
    }

    &__main {
      width: 100%;
    }

    &__grid {
      justify-content: space-between;
      gap: 40px;
      grid-template-columns: repeat(3, 250px);
    }
  }
}

@media (max-width: 1200px) {
  .page {
    &__grid {
      grid-template-columns: repeat(2, 250px);
    }
  }
}

@media (max-width: 900px) {
  .page {
    &__grid {
      justify-content: center;
      grid-template-columns: repeat(1, 250px);
    }
  }
}

@media (max-width: 600px) {
  .page {
    &__wrapper {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
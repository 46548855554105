<template>
  <div class="page">
    <h1 class="page__title">Delivery Policy</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__block">
          <p class="page__subtitle">SHIPPING DESTINATIONS</p>
          <p class="page__text">We provide delivery services to various locations worldwide.</p>
          <p class="page__text">You can view the full list of countries eligible for shipping on the checkout page.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">DELIVERY TIMEFRAMES</p>
          <p class="page__text">The estimated delivery window is 8-10 business days. Please note these timeframes are estimates and not guaranteed.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">SHIPPING COSTS</p>
          <p class="page__text">Shipping fees are calculated based on the destination.</p>
          <p class="page__text">Specific shipping cost details are available during checkout.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">ORDER PROCESSING</p>
          <p class="page__text">Orders are processed within 3 business days after being placed.</p>
          <p class="page__text">Processing includes verifying, packaging, and handing the order to the shipping carrier.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">TRACKING INFORMATION</p>
          <p class="page__text">Once your order has been shipped, you'll receive tracking information to monitor its status and location.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">INTERNATIONAL SHIPPING</p>
          <p class="page__text">For international shipments, import duties and taxes may apply.</p>
          <p class="page__text">You are responsible for any additional charges related to customs clearance.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">DELIVERY POLICY UPDATES</p>
          <p class="page__text">We may update our delivery policy periodically.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-block: 50px;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__subtitle {
    font-size: 16px;
    color: rgba(35,56,81,1);
    font-weight: 700;
  }

  &__ul {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(35,56,81,1);
  }

  &__link {
    font-weight: 700;
  }
}
</style>
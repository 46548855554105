<template>
  <div class="page">
    <h1 class="page__title">My Wish List</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__inner" v-if="products.length">
          <table class="page__table">
          <thead>
            <tr>
              <th>IMAGE</th>
              <th>PRODUCT NAME</th>
              <th>STOCK</th>
              <th>UNIT PRICE</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products" :key="product.node.uuid">
              <td class="page__table-text"><img :src="product.node.images.edges[0].node.image" :alt="product.node.name"></td>
              <td class="page__table-text">{{ product.node.name }}</td>
              <td class="page__table-text">{{ product.node.stocks?.edges[0].node.quantity }}</td>
              <td class="page__table-text">{{ product.node.price }}€</td>
              <td class="page__table-buttons">
                <button class="page__table-button" @click="addProductToCart(product.node.uuid)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.78571 5H18.2251C19.5903 5 20.5542 6.33739 20.1225 7.63246L18.4558 12.6325C18.1836 13.4491 17.4193 14 16.5585 14H6.07142M4.78571 5L4.74531 4.71716C4.60455 3.73186 3.76071 3 2.76541 3H2M4.78571 5L6.07142 14M6.07142 14L6.25469 15.2828C6.39545 16.2681 7.23929 17 8.23459 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM11 19C11 20.1046 10.1046 21 9 21C7.89543 21 7 20.1046 7 19C7 17.8954 7.89543 17 9 17C10.1046 17 11 17.8954 11 19Z"
                          stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
                <button @click="removeProductFromWishlist(product.node.uuid)" class="page__table-button"><img src="@icons/crossIcon.svg" alt="delete"></button>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <p class="page__noProducts" v-else>Your wish list is empty.</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useWishlistStore} from "@/stores/wishlist.js";
import {computed} from "vue";
import {Wishlist} from "@/core/api/wishlist.js";
import {useAuthStore} from "@/stores/auth.js";
import {Orders} from "@/core/api/order.js";
import {useCartStore} from "@/stores/cart.js";
import {useAppStore} from "@/stores/app.js";

const authStore = useAuthStore()
const appStore = useAppStore()
const cartStore = useCartStore()
const wishlistStore = useWishlistStore()

const cartId = computed(() => {
  return cartStore.currentOrder ? cartStore.currentOrder.uuid : null
})
const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})
const products = computed(() => {
  return wishlistStore.wishlist.products ? wishlistStore.wishlist.products.edges : []
})

const addProductToCart = async (productId) => {
  const res = await Orders.overwriteCart("addProductToPendingOrder", accessToken.value, cartId.value, productId)

  if (res.data.addProductToPendingOrder.order) {
    cartStore.currentOrder = res.data.addProductToPendingOrder.order

    const popup = {
      text: 'You have successfully added the product to cart!',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}

const removeProductFromWishlist = async (productId) => {
  const res = await Wishlist.overwriteWishlist("removeProductFromWishlist", productId, wishlistStore.wishlist.uuid, accessToken.value)

  if (res.data.removeProductFromWishlist.wishlist) {
    wishlistStore.wishlist = res.data.removeProductFromWishlist.wishlist

    const popup = {
      text: 'You have successfully removed product from wishlist!',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__inner {
    overflow: auto;
  }

  &__wrapper {
    padding-block: 50px;
    width: 85%;
    margin-inline: auto;
  }

  &__table {
    width: 100%;
    border: 1px solid rgba(221,221,221,1);
    border-collapse: collapse;

    & tr {
      border-bottom: 1px solid rgba(221,221,221,1);
    }

    & th {
      padding: 8px 14px;
    }

    &-text {
      text-align: center;
    }

    &-buttons {
      height: 97px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    &-button {
      cursor: pointer;
      width: 36px;
      aspect-ratio: 1;
      display: grid;
      place-items: center;
      background-color: $blue;
      border-radius: 2px;
      transition: 0.2s;
      color: $white;

      & img {
        width: 25px;
        aspect-ratio: 1;
      }

      &:hover {
        background-color: $orange;
      }

      &:last-child {
        background-color: $red;

        &:hover {
          background-color: $light-blue;
        }
      }
    }
  }

  &__noProducts {
    font-size: 16px;
    color: rgba(35,56,81,1);
  }
}

img {
  width: 90px;
  aspect-ratio: 1;
}
</style>
<template>
  <div class="reset">
    <div class="reset__form">
      <p class="reset__title">Enter the new password you wish to use.</p>
      <div class="reset__block">
        <div class="reset__name required">Password</div>
        <div class="reset__inner">
          <input
              type="password"
              placeholder="Password"
              class="reset__block-input"
              v-model="password"
              @input="onPasswordInput"
          >
          <p class="reset__block-error" v-if="passwordError">{{ passwordError }}</p>
        </div>
      </div>
      <div class="reset__block">
        <div class="reset__name required">Confirm password</div>
        <div class="reset__inner">
          <input
              type="password"
              placeholder="Confirm password"
              class="reset__block-input"
              v-model="passwordConfirm"
              @input="onPasswordConfirmInput"
          >
          <p class="reset__block-error" v-if="passwordConfirmError">{{ passwordConfirmError }}</p>
        </div>
      </div>
    </div>
    <div class="reset__line"></div>
    <div class="reset__buttons">
      <button @click="back" class="reset__button red">
        <img class="rotate" src="@icons/arrowIcon.svg" alt="back">
        Back
      </button>
      <button
          class="reset__button"
          @click="resetPassword"
          :disabled="!isFormValid"
      >
        Continue
        <img src="@icons/arrowIcon.svg" alt="continue">
      </button>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {computed, ref} from "vue";
import {isPasswordValid} from "@/core/rules/textFieldRules.js";
import {Auth} from "@/core/api/auth.js";
import {useAppStore} from "@/stores/app.js";

const route = useRoute()
const router = useRouter()
const appStore = useAppStore()

const back = () => {
  router.go(-1)
}

const uid = computed(() =>
  route.query['uid'] ? (route.query['uid']) : undefined
)
const token = computed(() =>
  route.query['token'] ? (route.query['token']) : undefined
)

const password = ref('')
const passwordConfirm = ref('')

const passwordError = ref('')
const passwordConfirmError = ref('')

const onPasswordInput = (e) => {
  if (isPasswordValid(e.target.value) !== true) {
    passwordError.value = isPasswordValid(e.target.value)
  } else {
    passwordError.value = ""
  }
}
const onPasswordConfirmInput = (e) => {
  if (password.value !== passwordConfirm.value) {
    passwordConfirmError.value = "Passwords don't match!"
  } else {
    passwordConfirmError.value = ""
  }
}

const isFormValid = computed(() => {
  return (
    isPasswordValid(password.value) === true &&
    password.value === passwordConfirm.value
  )
})

const resetPassword = async () => {
  const res = await Auth.confirmResetPassword(token.value, uid.value, password.value, passwordConfirm.value)

  if (res.data.confirmResetPassword.success) {
    const popup = {
      text: 'Your password was changed successfully!',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  } else {
    const popup = {
      text: 'Something went wrong!',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.reset {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
  padding-block: 20px;

  &__text {
    font-size: 16px;
    color: rgba(35,56,81,1);
  }

  &__title {
    color: rgba(35,56,81,1);
    font-weight: 700;
    font-size: 15px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__form {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;

    &-input {
      width: 400px;
      height: 34px;
      border: 1px solid rgba(238, 238, 238, 1);
      padding-inline: 12px;

      color: #555;
      font-size: 14px;

      &:hover {
        border-color: rgba(221, 221, 221, 1);
      }
    }

    &-error {
      margin-top: 5px;
      color: $red;
      font-size: 12px;
    }
  }

  &__name {
    margin-top: 10px;
    display: flex;
    gap: 3px;

    font-size: 13px;
    color: rgba(35,56,81,1);
  }

  &__line {
    height: 1px;
    width: 100%;
    background-color: rgba(221, 221, 221, 1);
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    cursor: pointer;
    align-self: flex-end;
    background-color: $blue;
    display: flex;
    align-items: center;
    gap: 3px;
    transition: 0.2s;
    border-radius: 3px;
    padding: 12px 15px;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }
}

.rotate {
  transform: rotate(0.5turn);
}

.red {
  background-color: $red;

  &:hover {
    background-color: $light-blue;
  }
}

.required {

  &::after {
    content: "*";
    color: rgba(232, 40, 77, 1);
  }
}
</style>
<template>
  <div class="page">
    <h1 class="page__title">Latest News</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__news">
          <div class="page__news-item">
            <h6>Calling All Artists: Join Our Sticker Revolution!</h6>
            <p>Are you a talented artist with a passion for creating eye-catching stickers? Do you dream of sharing your art with a wider audience and turning your c..</p>
            <button class="page__news-button">READ MORE</button>
          </div>
          <div class="page__news-item">
            <h6>Unleash Your Creativity: 10 Fun Ways to Use Stickers in Your Everyday Life</h6>
            <p>Stickers have come a long way from being just a playful addition to your notebook or laptop. In today's world, stickers have evolved into versatile to..</p>
            <button class="page__news-button">READ MORE</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    padding-block: 30px;
  }

  &__news {
    display: flex;
    gap: 30px;
    align-items: flex-start;

    &-item {
      width: 305px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      & h6 {
        color: rgba(48, 95, 124, 1);
        font-size: 16px;
        font-weight: 700;
      }

      & p {
        text-align: center;
        color: rgba(109, 118, 125, 1);
      }
    }

    &-button {
      cursor: pointer;
      width: fit-content;
      border: 1px solid $dark-blue;
      padding: 12px 15px;
      background-color: $white;
      border-radius: 3px;
      transition: 0.2s;

      font-size: 12px;
      font-weight: 700;
      color: $dark-blue;

      &:hover {
        box-shadow: 0 5px 30px -5px rgba(0,0,0,0.15);
        color: $orange;
      }
    }
  }
}
</style>
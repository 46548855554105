<template>
  <div class="update">
    <p class="update__title">Your password</p>
    <div class="update__form">
      <div class="update__block">
        <div class="update__name required">Password</div>
        <div class="update__block-inner">
          <input
              type="password"
              placeholder="Password"
              class="update__block-input"
              v-model="password"
              @input="onPasswordInput"
          >
          <p class="update__block-error" v-if="passwordError">{{ passwordError }}</p>
        </div>
      </div>
      <div class="update__block">
        <div class="update__name required">Password Confirm</div>
        <div class="update__block-inner">
          <input
              type="password"
              placeholder="Last Name"
              class="update__block-input"
              v-model="passwordConfirm"
              @input="onPasswordConfirmInput"
          >
          <p class="update__block-error" v-if="passwordConfirmError">{{ passwordConfirmError }}</p>
        </div>
      </div>
    </div>
    <div class="update__line"></div>
    <div class="update__buttons">
      <button @click="back" class="update__button red">
        <img class="rotate" src="@icons/arrowIcon.svg" alt="back">
        Back
      </button>
      <button
          class="update__button"
          @click="updateInfo"
          :disabled="!isFormValid"
      >
        Continue
        <img src="@icons/arrowIcon.svg" alt="continue">
      </button>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {isPasswordValid, required} from "@/core/rules/textFieldRules.js";
import {useAuthStore} from "@/stores/auth.js";
import {useRouter} from "vue-router";
import {Auth} from "@/core/api/auth.js";
import {useAppStore} from "@/stores/app.js";

const router = useRouter()
const authStore = useAuthStore()
const appStore = useAppStore()

const userUuid = computed(() => {
  return authStore.user ? authStore.user.uuid : null
})
const userAccessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})

const password = ref('')
const passwordConfirm = ref('')

const passwordError = ref('')
const passwordConfirmError = ref('')

const onPasswordInput = (e) => {
  if (required(e.target.value) !== true) {
    passwordError.value = required(e.target.value)
  } else {
    passwordError.value = ""
  }
}
const onPasswordConfirmInput = (e) => {
  if (password.value !== passwordConfirm.value) {
    passwordConfirmError.value = "Passwords don't match!"
  } else {
    passwordConfirmError.value = ""
  }
}

const isFormValid = computed(() => {
  return (
    isPasswordValid(password.value) === true &&
    password.value === passwordConfirm.value
  )
})

const back = () => {
  router.go(-1)
}

const updateInfo = async () => {
  const res = await Auth.UpdateData(
    userAccessToken.value,{
    query: `mutation {
      updateUser(
        uuid: "${userUuid.value}"
        password: "${password.value}"
        confirmPassword: "${passwordConfirm.value}"
      ) {
        user {
          uuid
          firstName
          lastName
          email
          phoneNumber
          isSubscribed
          balance {
            amount
          }
        }
      }
    }`
  })

  if (res.data.updateUser?.user) {
    const popup = {
      text: 'Your account has been successfully updated.',
      tag: 'success',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)

    authStore.setUser({
      user: res.data.updateUser.user,
      accessToken: res.data.updateUser.accessToken
    })
  } else {
    const popup = {
      text: 'Something went wrong',
      tag: 'fail',
      id: Math.random() * 2000
    }
    appStore.popupArray.unshift(popup)
    appStore.setPopupVisible(true)
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.update {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;

  &__title {
    text-transform: uppercase;
    color: $dark-blue;
    font-weight: 700;
    font-size: 16px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }

  &__form {
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  &__block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-input {
      width: 400px;
      height: 34px;
      border: 1px solid rgba(238, 238, 238, 1);
      padding-inline: 12px;

      color: #555;
      font-size: 14px;

      &:hover {
        border-color: rgba(221, 221, 221, 1);
      }
    }

    &-error {
      margin-top: 5px;
      color: $red;
      font-size: 12px;
    }
  }

  &__name {
    margin-top: 10px;
    display: flex;
    gap: 3px;

    font-size: 13px;
    color: rgba(35,56,81,1);
  }

  &__line {
    margin-top: 20px;
    height: 1px;
    width: 100%;
    background-color: rgba(221, 221, 221, 1);
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    cursor: pointer;
    align-self: flex-end;
    background-color: $blue;
    display: flex;
    align-items: center;
    gap: 3px;
    transition: 0.2s;
    border-radius: 3px;
    padding: 12px 15px;

    color: $white;
    font-size: 13px;
    font-weight: 700;

    &:hover {
      background-color: $orange;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $dark-grey;
    }

    &:disabled:hover {
      background-color: $dark-grey;
    }
  }
}

.required {

  &::after {
    content: "*";
    color: rgba(232, 40, 77, 1);
  }
}

.rotate {
  transform: rotate(0.5turn);
}

.red {
  background-color: $red;

  &:hover {
    background-color: $light-blue;
  }
}

@media (max-width: 600px) {
  .update {
    &__form {
      width: 100%;
    }

    &__block {
      flex-direction: column;
      gap: 5px;

      &-inner {
        width: 100%;
      }

      &-input {
        width: 100%;
      }
    }
  }
}
</style>
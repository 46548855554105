<template>
  <div class="page">
    <h1 class="page__title">Contact Us</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__inner">
          <div class="page__block">
            <div class="page__image">
              <img src="@icons/smileIcon.svg" alt="smile">
            </div>
            <div class="page__block-texts">
              <h6>{{ companyInfo?.companyName }}</h6>
              <p>{{ companyInfo?.companyPhone }}</p>
            </div>
          </div>
          <div class="page__block">
            <div class="page__image">
              <img src="@icons/mailFilledIcon.svg" alt="mail">
            </div>
            <div class="page__block-texts">
              <h6>Write us</h6>
              <p>{{ companyInfo?.companyEmail }}</p>
            </div>
          </div>
        </div>
        <contact-form />
      </div>
    </div>
  </div>
</template>

<script setup>
import ContactForm from "@/components/forms/contact-form.vue";
import {computed} from "vue";
import {useCompanyStore} from "@/stores/company.js";

const companyStore = useCompanyStore()

const companyInfo = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo : null
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__inner {
    display: flex;
    align-items: center;
    gap: 50px;
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 15px;

    &-texts {
      display: flex;
      flex-direction: column;

      & h6 {
        color: rgba(35, 56, 81, 1);
        font-size: 16px;
        font-weight: 700;
      }

      & p {
        color: rgba(109, 118, 125, 1);
        font-size: 12px;
      }
    }
  }
  
  &__image {
    background-color: $light-blue;
    border-radius: 50%;
    padding: 20px;
    display: grid;
    place-items: center;
  }
}

@media (max-width: 550px) {
  .page {
    &__inner {
      flex-direction: column;
      gap: 20px;
    }

    &__block {
      width: 100%;
    }
  }
}
</style>
<template>
  <div class="skeleton">
    <div class="skeleton__image"></div>
    <div class="skeleton__name"></div>
    <div class="skeleton__description"></div>
    <div class="skeleton__button"></div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
@keyframes pulse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes pulse2 {
  0% {
    transform: translateX(0);
    opacity: 0.3%;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: translateX(50rem);
    opacity: 0.3%;
  }
}

.skeleton {
  width: 250px;
  height: 300px;
  border-radius: 10%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  position: relative;
  animation: pulse 1.2s linear infinite alternate;
  background-color: rgba(147, 147, 147, 0.3);
  padding: 20px 25px;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    box-shadow: 1rem 1rem 1rem 1rem rgba(255, 255, 255, 0.6);
    animation: pulse2 2s linear infinite;
  }

  &__image {
    width: 100%;
    height: 236px;
    border-radius: 2px;
    background-color: rgba(204, 204, 204, 0.7);
  }

  &__name {
    width: 100%;
    height: 20px;
    border-radius: 2px;
    background-color: rgba(204, 204, 204, 0.6);
  }

  &__description {
    width: 100%;
    height: 20px;
    border-radius: 2px;
    background-color: rgba(204, 204, 204, 0.6);
  }

  &__button {
    align-self: flex-start;
    width: 93px;
    height: 35px;
    border-radius: 2px;
    background-color: rgba(204, 204, 204, 0.6);
  }
}
</style>